import { useState } from 'react';
import { breakpoints, Button, Dialog, Heading, Inline, Input, Stack } from '@etg/wings';
import { css } from '@eti/styles';
import { useTranslation } from '@eti/providers';
import { PRESELECTION_VALUES } from '../../../constants/productConstants';
import {
  ONE_WAY_COMBINATION,
  VALID_WITH_VOUCHER,
  VIRTUAL_INTERLINING,
  VIRTUAL_INTERLINING_WITH_SHORT_CONNECTION_TIME,
} from '../../../constants/tripCharacteristics';
import { capitalize } from '../utils';
import RadioSelection from './common/RadioSelection';
import SelectBoxSelection from './common/SelectBoxSelection';
import { DebugButton } from '.';

const dateInputFormatOptions = [
  { label: 'yyyy-MM-dd', value: 'yyyy-MM-dd' },
  { label: 'MM-dd-yyyy', value: 'MM-dd-yyyy' },
  { label: 'dd-MM-yyyy', value: 'dd-MM-yyyy' },
];

const tripCharacteristicsOptions = [
  { label: 'Virtual Interlining', value: VIRTUAL_INTERLINING },
  { label: 'Short connection', value: VIRTUAL_INTERLINING_WITH_SHORT_CONNECTION_TIME },
  { label: 'Valid with voucher', value: VALID_WITH_VOUCHER },
  { label: 'One way combination', value: ONE_WAY_COMBINATION },
];

const travelerDetailsModalOptions = [
  { label: 'NONE', value: 'NONE' },
  { label: 'SEATMAP', value: 'SEATMAP' },
  { label: 'REVIEW', value: 'REVIEW' },
];

const searchResultFlexBehaviourOptions = [
  { label: 'NONE', value: 'NONE' },
  { label: 'AIR_REBOOKING', value: 'AIR_REBOOKING' },
];

const resultPageVersionOptions = [
  { label: '1', value: '1' },
  { label: '3', value: '3' },
];

const resultPageQuickSortOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

const yourSelectedTripPageBrandedFaresVersion = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

const resultPageTicketVersionOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
];

const orderStatusPageStateOptions = [
  { label: 'CONFIRMED', value: 'CONFIRMED' },
  { label: 'PROCESSING', value: 'PROCESSING' },
  { label: 'FAILED', value: 'FAILED' },
];

const orderStatusPageOrderedAtThresholdOptions = [
  { label: 'Just now', value: '0' },
  { label: '12+ hours ago', value: '12' },
  { label: '24+ hours ago', value: '24' },
  { label: '36+ hours ago', value: '36' },
  { label: '48+ hours ago', value: '48' },
  { label: '60+ hours ago', value: '60' },
];

const tripDetailsNumberOfSegmentsOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
];

const tripDetailsNumberOfBoundsOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
];

const submitButtonStyles = css`
  min-width: 200px;
`;

const fieldSetStyles = css`
  border: 1px solid #bbb;
  border-radius: 3px;
  padding: 56px 16px 16px;
  position: relative;
`;

const legendStyles = css`
  position: absolute;
  top: 16px;
`;

const multiValueStyles = css`
  background: #eaeaea;
  border-radius: 6px;
  font-family: 'Menlo', sans-serif;
  font-size: 0.75rem;
  margin-inline-end: 4px;
  padding: 3px 6px 2px;
`;

const collectMultipleValuesFor = (inputKey, formEntries) =>
  formEntries
    .filter(([key]) => key.startsWith(inputKey))
    // eslint-disable-next-line no-unused-vars
    .map(([_, value]) => value);

const handleSubmit = (event) => {
  const formData = new FormData(event.target);
  const formEntries = Array.from(formData.entries());
  const selectBoxMultiValues = [
    ['tripCharacteristics', collectMultipleValuesFor('tripCharacteristics', formEntries)],
  ];

  const multiSelectInputIds = selectBoxMultiValues.map(([key]) => key);
  const reducedFormEntries = formEntries.filter(
    ([key]) => !multiSelectInputIds.some((id) => key.startsWith(id)),
  );

  for (const [key, value] of reducedFormEntries) {
    sessionStorage.setItem(`mocked${capitalize(key)}`, String(value));
  }

  for (const [key, value] of selectBoxMultiValues) {
    sessionStorage.setItem(
      `mocked${capitalize(key)}`,
      String(Array.isArray(value) ? `[${value.join(':')}]` : value),
    );
  }

  const productPreselection = formData.get('isFlexibleTicketIncludedOnTrip');
  sessionStorage.setItem('mockedProductPreselection', String(productPreselection));
};

const DebugSiteProperties = () => {
  const { t } = useTranslation();
  const [shouldShow, setShouldShow] = useState(false);
  const defaultHideFlexTicketForCarriers =
    sessionStorage.getItem('mockedHideFlexTicketForCarriers') || '';
  const [hideFlexTicketForCarriers, setHideFlexTicketForCarriers] = useState(
    defaultHideFlexTicketForCarriers,
  );
  const defaultHideCFARForCarriers = sessionStorage.getItem('mockedHideCFARForCarriers') || '';
  const [hideCFARForCarriers, setHideCFARForCarriers] = useState(defaultHideCFARForCarriers);

  return (
    <>
      <DebugButton
        data-testid="siteProperties-button"
        onClick={() => {
          setShouldShow(true);
        }}
      >
        Site properties
      </DebugButton>
      <Dialog
        closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
        footer={
          <Inline align="end" collapseBelow={breakpoints._560}>
            <Button
              className={submitButtonStyles}
              data-testid="sitePropertiesSave-button"
              form="sitePropertiesConfigForm"
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Inline>
        }
        isOpen={shouldShow}
        onDismiss={() => {
          setShouldShow(false);
        }}
        title="Site properties"
      >
        <form
          action="/debug.siteproperties.mock.config"
          id="sitePropertiesConfigForm"
          method="POST"
          onSubmit={handleSubmit}
        >
          <Stack>
            <SelectBoxSelection
              data-testid="dataInputFormat-dropdown"
              defaultValue={dateInputFormatOptions[0].value}
              label="Date input format"
              name="dateInputFormat"
              options={dateInputFormatOptions}
            />
            <SelectBoxSelection
              data-testid="tripCharacteristics-dropdown"
              defaultValue={[]}
              formatSelectedOption={(values) =>
                values.map((value) => (
                  <span className={multiValueStyles} key={value.value}>
                    {value.label}
                  </span>
                ))
              }
              isMultiple
              label="Trip characteristics"
              name="tripCharacteristics"
              options={tripCharacteristicsOptions}
              placeholder="Please select…"
            />
            <Inline>
              <SelectBoxSelection
                data-testid="resultPageVersion-dropdown"
                defaultValue={resultPageVersionOptions[0].value}
                label="Result Page Version"
                name="resultPageVersion"
                options={resultPageVersionOptions}
              />
              <SelectBoxSelection
                data-testid="resultPageTicketVersion-dropdown"
                defaultValue={resultPageTicketVersionOptions[2].value}
                label="Result Ticket Version"
                name="resultPageTicketVersion"
                options={resultPageTicketVersionOptions}
              />
            </Inline>
            <Inline>
              <SelectBoxSelection
                data-testid="resultPageQuickSortVersion-dropdown"
                defaultValue={resultPageQuickSortOptions[0].value}
                label="Result Page Quick Sort Version"
                name="resultPageQuickSortVersion"
                options={resultPageQuickSortOptions}
              />
            </Inline>
            <SelectBoxSelection
              data-testid="yourSelectedTripPageBrandedFaresVersion-dropdown"
              defaultValue={yourSelectedTripPageBrandedFaresVersion[0].value}
              label="Your Selected Trip Page Branded Fare Version"
              name="yourSelectedTripPageBrandedFaresVersion"
              options={yourSelectedTripPageBrandedFaresVersion}
            />
            <RadioSelection
              defaultValue="yes"
              id="shouldDisplayTripProviders"
              label="Display Trip Providers and Debug Filters?"
            />
            <RadioSelection
              defaultValue="no"
              id="alternateSearchBarEnabled"
              label="Enable alternate search bar?"
            />
            <RadioSelection
              defaultValue="no"
              id="shouldEnableAffiliateTravelAgencyBookingFlow"
              label="Enable Affiliate Travel Agency (ATA) booking flow"
            />
            <RadioSelection
              defaultValue="no"
              id="hideHeaderAndFooter"
              label="Hide site header and footer?"
            />
            <RadioSelection
              defaultValue="no"
              id="isSelfServiceRebookingEnabled"
              label="Activate self service rebooking?"
            />
            <RadioSelection
              defaultValue="no"
              id="isSATEventTrackingEnabled"
              label="Enable SAT event tracking?"
            />
            <RadioSelection
              defaultValue="no"
              id="isMoreSearchOptionsEnabled"
              label="Enable More search options in search form?"
            />
            <RadioSelection
              defaultValue="no"
              id="showPriceDropProtectionBanner"
              label="Show Price Drop protection banner?"
            />
            <RadioSelection
              defaultValue="no"
              id="topHeaderNotification"
              label="Show Top Header Notification?"
            />
            <RadioSelection
              defaultValue="no"
              id="mobileCalendarEnabled"
              label="Enable mobile calendar?"
            />
            <RadioSelection
              defaultValue="no"
              id="carrierFlightConfirmationEnabled"
              label="Enable Carrier Flight Confirmation?"
            />
            <RadioSelection
              defaultValue="no"
              id="showAncillaryCarrierPriceBreakdowns"
              label="Show Ancillary CarrierPriceBreakdowns in Products"
            />
            <RadioSelection
              defaultValue="no"
              id="showCarrierPriceBreakdowns"
              label="Show CarrierPriceBreakdowns in TravelerPrices"
            />
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Traveler Details Page Properties
                </Heading>
                <RadioSelection
                  defaultValue="yes"
                  id="showNewsletterCheckbox"
                  label="Show newsletter checkbox on Traveler Details Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="showConfirmEmail"
                  label="Show email confirmation on Traveler Details Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="freeCancellationEnable"
                  label="Enable free cancellation information notice on traveler details page?"
                />
              </Stack>
            </fieldset>
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Payment Page Properties
                </Heading>
                <RadioSelection
                  defaultValue="yes"
                  id="isAddressFormDisplayed"
                  label="Show client address on Payment Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="showDiscountCode"
                  label="Show discount code on Payment Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="showDiscountPrice"
                  label="Show discount price on Payment Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="isAlternativePaymentMethodOrder"
                  label="Alternative payment method order?"
                />
              </Stack>
            </fieldset>
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Order Page Properties
                </Heading>
                <RadioSelection
                  defaultValue="no"
                  id="showUpsellHotelsCom"
                  label="Show hotels.com banner on Order Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="showUpsellBookingCom"
                  label="Show booking.com banner on Order Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="showUpsellRentalCars"
                  label="Show rental cars banner on Order Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="showBrandedBanner"
                  label="Show Branded Banner on Order Page?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="showTaxBreakdown"
                  label="Show Tax Breakdown in cart and on Order Page?"
                />
              </Stack>
            </fieldset>
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Dynamic Booking Flow Properties
                </Heading>
                <RadioSelection
                  defaultValue="no"
                  id="isDynamicBookingFlowEnabled"
                  label="Enable Dynamic Booking Flow"
                />
                <RadioSelection
                  defaultValue="no"
                  id="isDynamicBookingFlowReviewPageEnabled"
                  label="Enable Review Page on Dynamic Booking Flow"
                />
              </Stack>
            </fieldset>
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Product/Ancillary settings
                </Heading>
                <RadioSelection
                  defaultValue="no"
                  id="isBaggageIncluded"
                  label="Baggage included?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="isCabinBaggageIncluded"
                  label="Cabin Baggage included?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="isFlexibleTicketIncludedOnTrip"
                  label="Include FlexibleTicket on trip?"
                />
                <RadioSelection
                  defaultValue={PRESELECTION_VALUES.none}
                  id="productPreSelection"
                  label="Pre-selection for extra products"
                  options={[
                    { label: 'Preselect yes', value: PRESELECTION_VALUES.yes },
                    { label: 'Preselect no', value: PRESELECTION_VALUES.no },
                    { label: 'Preselect none', value: PRESELECTION_VALUES.none },
                  ]}
                />
                <RadioSelection
                  defaultValue="no"
                  id="isClimateCompensationIncludedOnTrip"
                  label="Include ClimateCompensation on trip?"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="isPremiumPackageModalEnabled"
                  label="Premium package modal enabled?"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="isCheckInBaggageModalEnabled"
                  label="Check in baggage modal enabled?"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="isFlexibleTicketModalEnabled"
                  label="Flexible ticket modal enabled?"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="optionalProductsRequireValidation"
                  label="Enable isRequired validation for optional products on TravelerDetails?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="baggagePopupContinue"
                  label="Enable Baggage popup continue modal on TravelerDetails?"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="showProductsPerTraveler"
                  label="Show products per traveler?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="newAncillaryCardBody"
                  label="Enable new Ancillary Card Body?"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="showExtraProductImages"
                  label="Show images in the extra-products?"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="isPrimarySecondaryProductsEnabled"
                  label="Primary Secondary Products Enabled?"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="isTravelerDetailsWizardEnabled"
                  label="Enable TravelerDetails Wizard?"
                />
                <RadioSelection
                  defaultValue="no"
                  id="isNewAncillaryBundleEnabled"
                  label="Enable new Ancillary Bundle"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="isBrandedFaresEnabled"
                  label="Enable Branded Fares"
                />
                <Inline collapseBelow={breakpoints._560}>
                  <SelectBoxSelection
                    data-testid="searchResultFlexBehaviour-dropdown"
                    defaultValue={searchResultFlexBehaviourOptions[0].value}
                    label="Search Result Flex Behaviour"
                    name="searchResultFlexBehaviour"
                    options={searchResultFlexBehaviourOptions}
                  />
                  <SelectBoxSelection
                    data-testid="travelerDetailsModal-dropdown"
                    defaultValue={travelerDetailsModalOptions[0].value}
                    label="Traveler Details Modal"
                    name="travelerDetailsModal"
                    options={travelerDetailsModalOptions}
                  />
                </Inline>
                <Stack spacing={4}>
                  <Input.Label htmlFor="hideFlexTicketForCarriers">
                    Hide flex ticket for carriers
                  </Input.Label>
                  <Input
                    id="hideFlexTicketForCarriers"
                    name="hideFlexTicketForCarriers"
                    onChange={({ target }) => {
                      setHideFlexTicketForCarriers(target.value);
                    }}
                    placeholder="List of carriers or ALL for all carriers"
                    value={hideFlexTicketForCarriers}
                  />
                </Stack>
                <Stack spacing={4}>
                  <Input.Label htmlFor="hideFlexTicketForCarriers">
                    Hide CFAR for carriers
                  </Input.Label>
                  <Input
                    id="hideCFARForCarriers"
                    name="hideCFARForCarriers"
                    onChange={({ target }) => {
                      setHideCFARForCarriers(target.value);
                    }}
                    placeholder="List of carriers or ALL for all carriers"
                    value={hideCFARForCarriers}
                  />
                </Stack>
              </Stack>
            </fieldset>
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Order Status Page Properties
                </Heading>
                <RadioSelection
                  defaultValue="no"
                  id="isOrderStatusPageEnabled"
                  label="Enable order status page"
                />
                <RadioSelection
                  defaultValue="no"
                  id="isOrderStatusPageChatEnabled"
                  label="Enable chat"
                />
                <SelectBoxSelection
                  data-testid="orderStatusPageState-dropdown"
                  defaultValue={orderStatusPageStateOptions[0].value}
                  label="State for order status page"
                  name="orderStatusPageState"
                  options={orderStatusPageStateOptions}
                />
                <SelectBoxSelection
                  data-testid="orderStatusPageOrderedAtThreshold-dropdown"
                  defaultValue={orderStatusPageOrderedAtThresholdOptions[0].value}
                  label="State for order status page"
                  name="orderStatusPageOrderedAtThreshold"
                  options={orderStatusPageOrderedAtThresholdOptions}
                />
              </Stack>
            </fieldset>
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Trip Settings
                </Heading>
                <SelectBoxSelection
                  data-testid="tripDetailsNumberOfBoundsOptions-dropdown"
                  defaultValue={tripDetailsNumberOfBoundsOptions[1].value}
                  label="Number of bounds"
                  name="tripDetailsNumberOfBounds"
                  options={tripDetailsNumberOfBoundsOptions}
                />
                <SelectBoxSelection
                  data-testid="tripDetailsNumberOfSegmentsOptions-dropdown"
                  defaultValue={tripDetailsNumberOfSegmentsOptions[2].value}
                  label="Number of segments per bound"
                  name="tripDetailsNumberOfSegments"
                  options={tripDetailsNumberOfSegmentsOptions}
                />
              </Stack>
            </fieldset>
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Regional settings Properties
                </Heading>
                <RadioSelection
                  defaultValue="yes"
                  id="isSettingsCountryEnabled"
                  label="Enable country selection"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="isSettingsLanguageEnabled"
                  label="Enable language selection"
                />
                <RadioSelection
                  defaultValue="yes"
                  id="isSettingsCurrencyEnabled"
                  label="Enable currency selection"
                />
                <RadioSelection
                  defaultValue="no"
                  id="isMultiLanguageEnabled"
                  label="Enable deprecated multi language selection"
                />
              </Stack>
            </fieldset>
          </Stack>
        </form>
      </Dialog>
    </>
  );
};

export default DebugSiteProperties;
