import { useEffect } from 'react';
/**
 * A custom React hook that utilizes MutationObserver to detect when a specific element is added to a target DOM element.
 * @param {Object} props - An object containing properties for the hook.
 * @param {RefObject<HTMLDivElement> | null} props.targetElement - The reference to the DOM element whose mutations are to be observed.
 * @param {string} props.selector - A CSS selector string used to find the added element within the target element's children.
 * @param {(element: HTMLElement) => void} props.onElementAdded - A callback function that is triggered when an element matching the selector is added to the target element.
 *
 * @example
 * const App = () => {
 *   const containerRef = useRef(null);
 *
 *   const handleElementAdded = (element) => {
 *     console.log('New element added:', element);
 *   };
 *
 *   useMutationObserver({
 *     targetElement: containerRef,
 *     selector: '.new-item', // Example CSS selector for demonstration purposes
 *     onElementAdded: handleElementAdded,
 *   });
 *
 *   return (
 *     <ref={containerRef}>
 *       <p>Initial content</p>
 *     </div >
 *   );
 * };
 */

export const useMutationObserver = _ref => {
  let {
    targetElement,
    selector,
    onElementAdded
  } = _ref;
  useEffect(() => {
    if (!targetElement || !onElementAdded) {
      return undefined;
    }

    /**
     * Callback function invoked by the MutationObserver. It checks for childList mutations and calls onElementAdded when a new element is added.
     * @param {MutationRecord[]} mutationsList - An array of mutation records indicating what changes occurred in the target element.
     */
    const mutationCallback = mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          var _targetElement$curren;
          // Look for the element that matches the selector
          const element = (_targetElement$curren = targetElement.current) === null || _targetElement$curren === void 0 ? void 0 : _targetElement$curren.querySelector(selector);
          if (mutation.addedNodes.length > 0 && element) {
            // Trigger the callback once the the element was added
            onElementAdded(element);
          }
        }
      });
    };
    const observer = new MutationObserver(mutationCallback);
    if (targetElement.current) {
      observer.observe(targetElement.current, {
        childList: true,
        subtree: true
      });
    }

    /**
     * Cleanup function to disconnect the MutationObserver when the component unmounts or dependencies change.
     */
    return () => {
      observer.disconnect();
    };
  }, [targetElement, selector, onElementAdded]);
};