import { insertArgument } from '../string/string';
import { getPriceFormatter } from './provider';
const formattedPrice = price => getPriceFormatter().formatPrice(price);
export const formatPrice = function (price, customFormatter) {
  if (customFormatter === void 0) {
    customFormatter = null;
  }
  // Convert the input to a numeric value
  const numericPrice = Number(price) || 0;
  const formatter = customFormatter || getPriceFormatter();
  return formatter.formatPrice(numericPrice);
};
export const textWithPrice = (text, price) => insertArgument(text, formattedPrice(price));
export const generatePrice = (totalPrice, totalPriceText, minPrice, minPriceText) => {
  if (totalPrice > 0) {
    return {
      price: textWithPrice(totalPriceText, totalPrice)
    };
  }
  if (minPrice !== undefined && minPriceText) {
    return {
      placeholder: textWithPrice(minPriceText, minPrice)
    };
  }
  return {};
};
export const getPriceDivided = (price, divider) => {
  if (typeof divider !== 'number' || !Number.isInteger(divider) || divider <= 0) {
    return null;
  }
  return divider > 1 ? Math.ceil(price / divider) : price;
};