export const createScript = (id, src, onScriptLoad) => {
  const script = document.createElement('script');
  script.setAttribute('src', src);
  script.setAttribute('id', id);
  script.setAttribute('async', 'true');
  document.head.appendChild(script);
  script.onload = onScriptLoad;
};
export const removeScript = id => {
  const scriptTag = document.getElementById(id);
  if (scriptTag) {
    document.head.removeChild(scriptTag);
  }
};