import { formValueSelector } from 'redux-form';
import flatMapDeep from 'lodash/flatMapDeep';
import isNumber from 'lodash/isNumber';
import { SEAT_MAP } from '../../products';
const formSection = SEAT_MAP.name;
const transformSeatMapObject = (boundIndex, segmentIndex, _ref) => {
  let {
    id: travelerId,
    row,
    column,
    seatPreference,
    deckType
  } = _ref;
  return {
    travelerId: isNumber(travelerId) ? String(travelerId) : travelerId,
    row,
    column,
    boundIndex: Number(boundIndex),
    segmentIndex,
    ...((row === undefined || row === null) && seatPreference ? {
      seatPreference
    } : {}),
    deckType
  };
};
const checkIfSegmentHasSeats = segment => {
  return Boolean(segment && (segment.column || segment.seatPreference && segment.seatPreference !== 'NO_OPTION'));
};
const getSeatMap = segments => {
  return flatMapDeep(segments, (segment, boundSegmentIndex) => {
    const boundSegment = boundSegmentIndex.split('-', 2);
    const boundIndex = Number(boundSegment[0]);
    const segmentIndex = Number(boundSegment[1]);
    return segment ? Object.keys(segment).filter(key => checkIfSegmentHasSeats(segment[key])).map(key => transformSeatMapObject(boundIndex, segmentIndex, {
      ...segment[key]
    })) : [];
  });
};
export const transformSeatmap = (id, _ref2) => {
  let {
    ...segments
  } = _ref2;
  const selectionSeatMap = getSeatMap(segments);
  if (!selectionSeatMap.length) {
    return null;
  }
  return {
    productId: id,
    selectionSeatMap: getSeatMap(segments)
  };
};
const getGraphQlSelection = _ref3 => {
  var _value$value;
  let {
    state,
    form
  } = _ref3;
  const value = formValueSelector(form)(state, formSection) || {};
  return (value === null || value === void 0 || (_value$value = value.value) === null || _value$value === void 0 ? void 0 : _value$value.value) === 'true' ? transformSeatmap(String(SEAT_MAP.id), value) : null;
};
export const getDBFGraphQlSelection = _ref4 => {
  var _value$value2, _value$value3;
  let {
    state,
    form
  } = _ref4;
  const value = formValueSelector(form)(state, formSection) || {};
  if ((value === null || value === void 0 || (_value$value2 = value.value) === null || _value$value2 === void 0 ? void 0 : _value$value2.value) === 'false') {
    return {
      productId: String(SEAT_MAP.id),
      selectionSeatMap: []
    };
  }
  return (value === null || value === void 0 || (_value$value3 = value.value) === null || _value$value3 === void 0 ? void 0 : _value$value3.value) === 'true' ? transformSeatmap(String(SEAT_MAP.id), value) : null;
};
export default getGraphQlSelection;