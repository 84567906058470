import { getAirports, translateNparseJsonProp } from '../../common/utils/productCardProps';
const isObjectEmpty = obj => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};
export const validateAvailabilityTranslation = availabilityProps => {
  try {
    if (isObjectEmpty(availabilityProps)) {
      return false;
    }
    const {
      availabilityCard,
      cart
    } = availabilityProps;
    if (isObjectEmpty(availabilityCard) || isObjectEmpty(cart)) {
      return false;
    }
    const {
      availabilityTitle,
      departing,
      return: returnKey,
      availableStatus,
      unavailableStatus,
      addedStatus,
      statusTooltip,
      addedTitle
    } = availabilityCard;
    const {
      purchasedMessage
    } = cart;
    return Boolean(availabilityTitle && departing && returnKey && availableStatus && unavailableStatus && addedStatus && statusTooltip && addedTitle && purchasedMessage);
  } catch (error) {
    return false;
  }
};
export const getAvailabilityList = (bounds, list) => {
  if (!list) {
    return [];
  }
  const boundAirports = getAirports(bounds);
  return list.filter(item => item != null).map(availability => {
    const airportIndex = boundAirports.findIndex(b => b.code === (availability === null || availability === void 0 ? void 0 : availability.departureAirport));
    if (airportIndex !== -1) {
      const {
        code,
        name
      } = boundAirports[airportIndex];
      return {
        ...availability,
        departureAirport: `${name} (${code})`
      };
    }
    return availability;
  });
};
export const getCartAvailability = (numberOfTravelers, cartTranslation, availabilityList) => {
  const availableAirports = availabilityList === null || availabilityList === void 0 ? void 0 : availabilityList.filter(airport => airport.available).map(airport => airport.departureAirport);
  return {
    hasAvailability: true,
    numberOfPass: numberOfTravelers,
    airportList: availableAirports,
    passLabel: cartTranslation
  };
};
export const withParseAvailability = (t, availabilityTextkey) => {
  var _availabilityTranslat, _availabilityTranslat2;
  const availabilityTranslation = translateNparseJsonProp(t, availabilityTextkey);
  const cartPurchase = (_availabilityTranslat = availabilityTranslation === null || availabilityTranslation === void 0 || (_availabilityTranslat2 = availabilityTranslation.cart) === null || _availabilityTranslat2 === void 0 ? void 0 : _availabilityTranslat2.purchasedMessage) !== null && _availabilityTranslat !== void 0 ? _availabilityTranslat : 'Pass';
  return (availability, bounds, numberOfTravelers) => getCartAvailability(numberOfTravelers, cartPurchase, getAvailabilityList(bounds, availability));
};