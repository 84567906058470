import { price as priceUtils } from '@eti/utils';
import { TripType } from '@eti/schema-types';
import { ProductCardModalType } from '../models/product';
import { UPSELL_TYPES } from './constants';
import { formatUpsell } from './upsellParse';
import { isPrimaryFlow } from './primaryFlow';
import { getPriceDivided as getPricePerBound } from './price';
const getStandardUpsell = upSellText => {
  const upSellObject = formatUpsell(upSellText);
  const shouldDisplayUpsell = upSellObject && Boolean(upSellObject.content);
  const isUpsellHighlight = shouldDisplayUpsell && (upSellObject === null || upSellObject === void 0 ? void 0 : upSellObject.type) === UPSELL_TYPES.PROMOTIONAL;
  return {
    upSellObject,
    isUpsellHighlight,
    shouldDisplayUpsell
  };
};
export const getAvailabilityUpsell = (upSellText, airportList, tripType) => {
  var _upSellObject$fullyAv, _upSellObject$partial;
  const upSellObject = formatUpsell(upSellText);
  if (!airportList || !(upSellObject !== null && upSellObject !== void 0 && (_upSellObject$fullyAv = upSellObject.fullyAvailable) !== null && _upSellObject$fullyAv !== void 0 && _upSellObject$fullyAv.trim()) || !((_upSellObject$partial = upSellObject.partiallyAvailable) !== null && _upSellObject$partial !== void 0 && _upSellObject$partial.trim())) {
    return getStandardUpsell(upSellText);
  }
  let shouldDisplayUpsell = false;
  if (airportList.length > 0) {
    var _upSellObject$type;
    const hasUnavailable = airportList.some(airport => !airport.available);
    const hasAvailable = airportList.some(airport => airport.available);
    upSellObject.content = hasUnavailable ? upSellObject.partiallyAvailable : upSellObject.fullyAvailable;
    upSellObject.type = (_upSellObject$type = upSellObject.type) !== null && _upSellObject$type !== void 0 ? _upSellObject$type : UPSELL_TYPES.INFOMATION;
    shouldDisplayUpsell = hasAvailable && tripType !== TripType.OneWay;
  }
  const isUpsellHighlight = shouldDisplayUpsell && (upSellObject === null || upSellObject === void 0 ? void 0 : upSellObject.type) === UPSELL_TYPES.PROMOTIONAL;
  return {
    upSellObject: {
      content: upSellObject.content,
      type: upSellObject.type
    },
    isUpsellHighlight,
    shouldDisplayUpsell
  };
};
export const getUpsellProps = (upSellText, availabilityData) => {
  return availabilityData !== null && availabilityData !== void 0 && availabilityData.shouldDisplayAvailability ? getAvailabilityUpsell(upSellText, availabilityData.availabilityList, availabilityData.tripType) : getStandardUpsell(upSellText);
};
export const getPriceLabel = (isSelected, t) => {
  const onlyLabel = t('Products.Price.Label.Only');
  const totalLabel = t('Products.Price.Label.Total');
  return isSelected ? totalLabel : onlyLabel;
};
export const getPriceProps = (t, isSelected, options) => {
  const {
    priceRaw,
    numberOfBounds,
    shouldShowPricePerBound: isPerBound,
    shouldShowPricePerTraveler: isPerTraveler
  } = options;
  const perBoundsLabel = t('Products.Price.Label.PerBounds');
  const perTravelerLabel = t('Products.Price.Label.Person');
  const perBoundsAndTravelerLabel = t('Product.Price.Per.Bound.And.Person');
  const price = priceUtils.formatPrice(isPerBound && !isSelected ? getPricePerBound(priceRaw, numberOfBounds) : priceRaw);
  const priceLabel = getPriceLabel(isSelected, t);
  let priceSuffix = '';
  const isOnlyPerTraveler = isPerTraveler && !isPerBound;
  const isOnlyPerBound = !isPerTraveler && isPerBound && numberOfBounds > 1 && perBoundsLabel && !isSelected;
  const isPerTravelerAndBound = isPerTraveler && isPerBound && numberOfBounds > 1;
  if (isOnlyPerTraveler) {
    priceSuffix = perTravelerLabel;
  } else if (isOnlyPerBound) {
    priceSuffix = perBoundsLabel;
  } else if (isPerTravelerAndBound) {
    priceSuffix = perBoundsAndTravelerLabel;
  }
  return {
    priceLabel,
    price,
    priceSuffix
  };
};
export const getSingleChoiceProps = (t, p, nonDebugT) => {
  const shouldUseValidationLabel = p('IbeClient.TravelerDetails.OptionalProductsRequireValidation.Enabled');
  const isMainFlow = isPrimaryFlow();
  const label = t('Product.SelectOne.Label');
  const selectOneLabelMainFlow = shouldUseValidationLabel ? nonDebugT('Input.Field.Required', label) : '';
  const selectOneLabel = isMainFlow ? selectOneLabelMainFlow : '';
  const labelNo = t('Product.AncillaryRadio.Label.No');
  const labelYes = t('Product.AncillaryRadio.Label.Yes');
  return {
    selectOneLabel,
    labelNo,
    labelYes
  };
};
export const getReadMoreLabel = t => t('Products.ReadMore.Link.Label');
export const getModal = (showPersuasion, confirmationContent, persuasionContent) => {
  const hasPersuasion = Boolean(persuasionContent);
  const hasConfirmation = Boolean(confirmationContent);
  const productCardModal = {
    content: '',
    type: ProductCardModalType.None
  };
  if (hasConfirmation) {
    productCardModal.content = confirmationContent;
    productCardModal.type = ProductCardModalType.Confirmation;
  } else if (showPersuasion && hasPersuasion) {
    productCardModal.content = persuasionContent;
    productCardModal.type = ProductCardModalType.Persuasion;
  }
  return productCardModal;
};
export const parseJsonProp = prop => {
  try {
    return JSON.parse(prop);
  } catch (error) {
    return null;
  }
};
export const translateNparseJsonProp = (t, prop) => {
  try {
    return JSON.parse(t(prop));
  } catch (error) {
    return null;
  }
};
export const getAirports = bounds => bounds.flatMap(bound => {
  var _bound$segments;
  return bound === null || bound === void 0 || (_bound$segments = bound.segments) === null || _bound$segments === void 0 ? void 0 : _bound$segments.filter(segment => (segment === null || segment === void 0 ? void 0 : segment.__typename) === 'TripSegment').map(tripSegment => {
    var _tripSegment$origin, _tripSegment$origin2;
    return {
      code: (_tripSegment$origin = tripSegment.origin) === null || _tripSegment$origin === void 0 ? void 0 : _tripSegment$origin.code,
      name: (_tripSegment$origin2 = tripSegment.origin) === null || _tripSegment$origin2 === void 0 ? void 0 : _tripSegment$origin2.name
    };
  });
});