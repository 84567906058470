export const brands = {
  ADR: 'adr',
  AZULMUNDO: 'azulmundo',
  BOOKBARBADOS: 'bookbarbados',
  BOOKING: 'booking',
  BUSINESSCLASS: 'businessclass',
  FANTASTICGREECE: 'fantasticgreece',
  FLIGHTNETWORK: 'flightnetwork',
  FLYBILLET: 'flybillet',
  FLYFAR: 'flyfar',
  GOTOGATE: 'gotogate',
  HOTELIERMART: 'hoteliermart',
  JOURNEYDETAILS: 'journeydetails',
  MYTRIP: 'mytrip',
  NOGKO: 'nokgo',
  RADISSONHOTELS: 'radissonhotels',
  RCI: 'rci',
  ROME2RIO: 'rome2rio',
  SEAT24: 'seat24',
  SERKO: 'serko',
  SOUTHWEST: 'southwest',
  SUBIRTE: 'subirte',
  SUPERSAVER: 'supersaver',
  TRAVELFINDER: 'travelfinder',
  TRAVELSTART: 'travelstart',
  TRAVELVENTURA: 'travelventura',
  TRIP: 'trip',
  TRIPBEAT: 'tripbeat',
  TRIPSTACK: 'tripstack',
  TUI: 'tui',
  WYNDHAM: 'wyndham'
};