export const ProductCardModalType = {
  Confirmation: 0,
  None: 1,
  Persuasion: 2
};
export const ProductCardLayoutType = {
  Main: 0,
  New: 1,
  Old: 2,
  Small: 3,
  PostBooking: 4
};
export const BoundValues = {
  Departing: 0,
  Returning: 1
};