import { Button, Stack } from '@etg/wings';
import { useHistory } from 'react-router-dom';
import { css } from '@eti/styles';
import { useProperty, useSiteContext, useTranslation } from '@eti/providers';
import { useAuth } from '@eti/authentication';
import type { Dispatch, SetStateAction } from 'react';
import { AuthenticationType, LoginSource } from '@eti/schema-types';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingDots } from '@eti/components';
import { routes } from '../../../constants/routesAndApisConstants';
import { CRITICAL } from '../utils/messageTypes';
import { scrollToElement } from '../../../utils/scrollToElement';
import { redirectToActualUrl } from '../../utils/utils';
import FormInput from '../../components/formInput/FormInput';
import { handleWrongOrderNumberInputs } from '../utils/loginFormContainerUtils';
import {
  isRequired,
  isValidEmail,
  isValidOrderNumber,
  MAX_CHARS,
} from '../../components/formInput/validations';
import { SourceOfLogin } from './models';

const submitButtonStyles = css`
  display: block;
  margin: 0 auto;
  width: 296px;

  span {
    justify-content: center;
  }
`;

export interface LoginFormContainerProps {
  classNames?: Record<string, any>;
  handleLoginSuccess?: (email?: string, orderNumber?: string) => void;
  setNotification: Dispatch<
    SetStateAction<{ message: string; type: string; dataTestId: string } | null>
  >;
  source: keyof typeof SourceOfLogin;
}

export type LoginFormType = {
  email: string;
  orderNumber: string;
};

const LoginFormContainer = ({
  classNames,
  handleLoginSuccess,
  setNotification,
  source,
}: LoginFormContainerProps) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const history = useHistory();
  const { brand } = useSiteContext();
  const { authenticateUser, authenticateUserResult } = useAuth();

  const methods = useForm<LoginFormType>({
    mode: 'onBlur',
    defaultValues: { email: '', orderNumber: '' },
  });

  const { reset, handleSubmit } = methods;

  const orderNumberMaxLength = p('IbeClient.OrderNumber.MaxCharacters');

  const authenticate = async (email: string, orderNumber: string) => {
    const loginSourceMapping = {
      [SourceOfLogin.ORDER]: LoginSource.OrderLogin,
      [SourceOfLogin.MODAL]: LoginSource.OrderLogin,
      [SourceOfLogin.CONTACTUS]: LoginSource.ContactUsLogin,
      [SourceOfLogin.POSTBOOKING]: LoginSource.PostbookingLogin,
      [SourceOfLogin.RYANAIR]: LoginSource.RyanairLogin,
    };

    const authRequest = {
      email,
      orderNumber,
      authenticationType: AuthenticationType.OrderInfo,
      token: null,
      loginSource: loginSourceMapping[source],
    };
    const { data } = await authenticateUser(authRequest);
    const authenticateUserData = data?.authenticateUser;

    return {
      success: authenticateUserData?.authenticated,
      pageUrlForActualSiteWithLoadRef: authenticateUserData?.orderDetailsUrlForActualSite,
      brandCodeForActualSite: authenticateUserData?.brandCodeForActualSite,
      brandNameForActualSite: authenticateUserData?.brandNameForActualSite,
      hostNameForActualSite: authenticateUserData?.urlForActualSite,
      redirectionRoute: authenticateUserData?.redirectionRoute,
    };
  };

  const showNotificationInvalidCredentials = () => {
    setNotification({
      message: t('Postbooking.Login.Invalid.Email.OrderNumber.Combination.Error'),
      type: CRITICAL,
      dataTestId: 'invalid-email-order-combo-error-message',
    });
    scrollToElement('#loginMessage');
  };

  const showNotificationInternalError = () => {
    setNotification({
      message: t('Postbooking.Login.Internal.Error'),
      type: CRITICAL,
      dataTestId: 'login-service-error-message',
    });
    scrollToElement('#loginMessage');
  };

  const handleRedirectionFromSource = (
    values: LoginFormType,
    brandCodeForActualSite?: string | null,
    brandNameForActualSite?: string | null,
    pageUrlForActualSiteWithLoadRef?: string | null,
  ) => {
    if (handleLoginSuccess) {
      handleLoginSuccess(values.email, values.orderNumber);
    }
    if (pageUrlForActualSiteWithLoadRef) {
      redirectToActualUrl(
        brand,
        history,
        source === SourceOfLogin.ORDER
          ? `${pageUrlForActualSiteWithLoadRef}?source=order-details-login`
          : pageUrlForActualSiteWithLoadRef,
        brandCodeForActualSite,
        brandNameForActualSite,
      );
    }
  };

  const handleRedirectionWhenPartiallyLoggedIn = (
    values: LoginFormType,
    brandCodeForActualSite?: string | null,
    brandNameForActualSite?: string | null,
    pageUrlForActualSiteWithLoadRef?: string | null,
  ) => {
    /** Only the order login and the modal login are landing to the Welcome page.
     Postbooking and contact-us logins redirect to the corresponding pages in the actual site * */
    if (source === SourceOfLogin.MODAL || source === SourceOfLogin.ORDER) {
      if (handleLoginSuccess) {
        handleLoginSuccess(values.email, values.orderNumber);
      }
      history.push(routes.ORDER_LIST);
    } else {
      handleRedirectionFromSource(
        values,
        brandCodeForActualSite,
        brandNameForActualSite,
        pageUrlForActualSiteWithLoadRef,
      );
    }
  };

  const handleActualSubmit = async (values: LoginFormType) => {
    try {
      const { email, orderNumber } = values;
      const {
        success,
        pageUrlForActualSiteWithLoadRef,
        brandCodeForActualSite,
        brandNameForActualSite,
        redirectionRoute,
      } = await authenticate(email, orderNumber);

      const isPartiallyLoggedIn = Boolean(pageUrlForActualSiteWithLoadRef);

      if (success) {
        if (handleLoginSuccess) {
          handleLoginSuccess(email, orderNumber);
        }
        if (redirectionRoute) {
          history.push(window.spa_variables.routes[redirectionRoute]);
        }
      } else {
        if (isPartiallyLoggedIn) {
          handleRedirectionWhenPartiallyLoggedIn(
            values,
            brandCodeForActualSite,
            brandNameForActualSite,
            pageUrlForActualSiteWithLoadRef,
          );
        } else {
          showNotificationInvalidCredentials();
        }
        reset();
      }
    } catch (e) {
      reset();
      showNotificationInternalError();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className={classNames?.formStyles}
        noValidate
        onSubmit={handleSubmit(handleActualSubmit)}
      >
        <Stack spacing={24}>
          <FormInput
            data-testid="login-email"
            description={t('Postbooking.Login.Email.Helper.Text')}
            isRequired
            label={t('Postbooking.Login.Email')}
            maxLength={MAX_CHARS}
            name="email"
            placeholder={t('Postbooking.Login.Email.Placeholder.Text')}
            type="email"
            validations={{ ...isRequired(), ...isValidEmail() }}
          />
          <FormInput
            data-testid="login-order-number"
            description={t('Postbooking.Login.Order.Helper.Text')}
            isRequired
            label={t('Postbooking.Login.Order')}
            name="orderNumber"
            normalize={handleWrongOrderNumberInputs}
            placeholder={t('Postbooking.Login.Order.Placeholder.Text')}
            validations={{ ...isRequired(), ...isValidOrderNumber(orderNumberMaxLength) }}
          />
          <Button
            className={submitButtonStyles}
            data-testid="login-button"
            type="submit"
            variant="primary"
          >
            {authenticateUserResult.loading ? (
              <LoadingDots ariaLabel={t('ScreenReader.Login.Loading.AriaLabel')} />
            ) : (
              t('Postbooking.Login.SubmitButton')
            )}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default LoginFormContainer;
