import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnowplowTracking } from '@eti/providers';
import { AIR_HELP, ALL_INCLUSIVE_PROTECTION, BAGGAGE_SERVICE, BANKRUPTCY_INSURANCE_COVER_GENIUS, CABIN_BAGGAGE, CANCEL_24_HOURS, CANCEL_FOR_ANY_REASON, CANCELLATION_GUARANTEE, CANCELLATION_INSIDE_EU, CANCELLATION_INSURANCE_COVER_GENIUS, CANCELLATION_OUTSIDE_EU, CHECK_IN_BAGGAGE, CO2_COMPENSATION, COMPREHENSIVE_INSURANCE_COVER_GENIUS, CONNECTION_GUARANTEE, FAST_TRACK, FLEXIBLE_TICKET, MEAL, MOBILE_TRAVEL_PLAN, ONLINE_CHECK_IN, PLATINUM_SERVICE, QPASS, SERVICE_PACKAGE, SIMPLE_VISA, SMS, TRAVEL_DOCS_POST, TRAVEL_INSURANCE_COVER_GENIUS, TRAVEL_INSURANCE_INSIDE_EU, TRAVEL_INSURANCE_OUTSIDE_EU, TRIP_CANCELLATION_PROTECTION } from '../products';
import { useElementInViewport } from './useElementInViewport';
export const BUNDLE_VARIANTS = {
  BUNDLE_TD: 'Bundle TD',
  BUNDLE_YST: 'Bundle YST',
  ETG_FARES: 'ETG Fares'
};
export const getProductNameForTracking = productName => {
  const products = {
    [AIR_HELP.name]: 'AirHelp',
    [BAGGAGE_SERVICE.name]: 'Baggage Service',
    [BANKRUPTCY_INSURANCE_COVER_GENIUS.name]: 'Bankruptcy Insurance Cover Genius',
    [CABIN_BAGGAGE.name]: 'Cabin baggage',
    [CANCEL_FOR_ANY_REASON.name]: 'Cancel for any reason',
    [CANCELLATION_GUARANTEE.name]: 'Cancellation Guarantee Extra',
    [CANCELLATION_INSIDE_EU.name]: 'Cancellation Protection Within Europe',
    [CANCELLATION_INSURANCE_COVER_GENIUS.name]: 'Cancellation Insurance Cover Genius',
    [CANCELLATION_OUTSIDE_EU.name]: 'Cancellation Protection Outside Europe',
    [CHECK_IN_BAGGAGE.name]: 'Checked baggage',
    [COMPREHENSIVE_INSURANCE_COVER_GENIUS.name]: 'Comprehensive Insurance Cover Genius',
    [CO2_COMPENSATION.name]: 'Climate Compensation',
    [FLEXIBLE_TICKET.name]: 'Flexible ticket',
    [MEAL.name]: 'Meal Preference',
    [MOBILE_TRAVEL_PLAN.name]: 'Mobile Travel Plan',
    [ONLINE_CHECK_IN.name]: 'Check-in',
    [PLATINUM_SERVICE.name]: 'Platinum',
    [SIMPLE_VISA.name]: 'Simple Visa',
    [SMS.name]: 'Order Information SMS',
    [TRAVEL_INSURANCE_COVER_GENIUS.name]: 'Travel Insurance Cover Genius',
    [TRAVEL_INSURANCE_INSIDE_EU.name]: 'Travel Insurance Within Europe Solid',
    [TRAVEL_INSURANCE_OUTSIDE_EU.name]: 'Travel Insurance Outside Europe Solid',
    [TRIP_CANCELLATION_PROTECTION.name]: 'Manulife Cancellation Protection',
    [ALL_INCLUSIVE_PROTECTION.name]: 'Manulife All Inclusive',
    [FAST_TRACK.name]: 'Fast Track',
    [QPASS.name]: 'Q Pass',
    [TRAVEL_DOCS_POST.name]: 'Travel Docs Post',
    [CONNECTION_GUARANTEE.name]: 'Connection Guarantee',
    [SERVICE_PACKAGE.name]: 'Service Package',
    [CANCEL_24_HOURS.name]: 'Cancel 24 hours',
    [BUNDLE_VARIANTS.BUNDLE_TD]: 'Bundle TD',
    [BUNDLE_VARIANTS.BUNDLE_YST]: 'Bundle YST',
    [BUNDLE_VARIANTS.ETG_FARES]: 'ETG Fares'
  };
  return products[productName];
};
const getPageTitle = path => {
  return {
    '/result': 'Results',
    '/travel-details': 'Travel Details',
    '/postbooking/products': 'Post Booking Products',
    '/order-details/extra-products': 'Order Details',
    '/your-selected-trip': 'Your Selected Trip'
  }[path];
};
export const getProductPrice = function (targetElement, isSecondBag) {
  var _targetElement$curren, _targetElement$curren2;
  if (isSecondBag === void 0) {
    isSecondBag = false;
  }
  const productLayoutPrice = targetElement === null || targetElement === void 0 || (_targetElement$curren = targetElement.current) === null || _targetElement$curren === void 0 || (_targetElement$curren = _targetElement$curren.querySelector('.product-price')) === null || _targetElement$curren === void 0 ? void 0 : _targetElement$curren.innerText;
  const cabinBaggagePice = targetElement === null || targetElement === void 0 || (_targetElement$curren2 = targetElement.current) === null || _targetElement$curren2 === void 0 || (_targetElement$curren2 = _targetElement$curren2.querySelectorAll('[data-testid^="toggleChoice-cabinBaggage-content-true-"][data-testid$="-price"]')[0]) === null || _targetElement$curren2 === void 0 ? void 0 : _targetElement$curren2.innerText;
  let checkInBaggagePice;
  if (isSecondBag) {
    var _targetElement$curren3;
    checkInBaggagePice = targetElement === null || targetElement === void 0 || (_targetElement$curren3 = targetElement.current) === null || _targetElement$curren3 === void 0 || (_targetElement$curren3 = _targetElement$curren3.querySelectorAll('[data-testid*="combinedbag-true"][data-testid$="-price"]')[0]) === null || _targetElement$curren3 === void 0 ? void 0 : _targetElement$curren3.innerText;
  } else {
    var _ref, _targetElement$curren4, _targetElement$curren5;
    checkInBaggagePice = (_ref = (targetElement === null || targetElement === void 0 || (_targetElement$curren4 = targetElement.current) === null || _targetElement$curren4 === void 0 ? void 0 : _targetElement$curren4.querySelectorAll('[data-testid*="singlebag-true"][data-testid*="-price"]')[0]) || (targetElement === null || targetElement === void 0 || (_targetElement$curren5 = targetElement.current) === null || _targetElement$curren5 === void 0 ? void 0 : _targetElement$curren5.querySelectorAll('[data-testid^="toggleChoice-"][data-testid*="true"][data-testid$="-price"]')[0])) === null || _ref === void 0 ? void 0 : _ref.innerText;
  }
  return productLayoutPrice || cabinBaggagePice || checkInBaggagePice;
};
function getTitleFromPath(path) {
  let title = getPageTitle(path);
  if (title) {
    return title;
  }
  // Remove all slashes and hyphens with space
  title = path.replace(/[/-]/g, ' ').trim();
  // Capitalize the first letter of each word
  return title.replace(/\b\w/g, char => char.toUpperCase());
}
export function cleanText(rawData) {
  return rawData === null || rawData === void 0 ? void 0 : rawData.replace(/[^0-9.,-]+/g, '').trim();
}
const TRACKING_ACTIONS = {
  ADD_TO_CART: 'Add to Cart',
  CLICK: 'Click',
  ITEM_VIEW: 'Item view',
  POP_UP_VIEW: 'Pop up view',
  SELECT_NONE: 'Select none',
  SELECT: 'Select',
  DE_SELECT: 'Deselect'
};
export const TRACKING_PROPERTIES = {
  VIEW_OPTIONS: 'View options',
  ON_PAGE: 'On page',
  OVERLAY: 'Overlay',
  CLOSE_OVERLAY: 'Close Overlay',
  VALIDATION_POP_UP: 'Validation pop up',
  READ_MORE: 'Read more',
  TERMS_AND_CONDITIONS: 'Terms and Conditions'
};
export const TRACKING_VALUES = {
  ALL_PASSENGERS: 'All passengers',
  SINGLE_PASSENGER: 'Single passenger',
  PROVINCE_SELECT: 'Province select',
  RADIO_BUTTON: 'Radio button',
  SWITCH_BUTTON: 'Switch button'
};
export const useProductSnowplowTracking = function (productName, _temp) {
  var _window$spa_variables;
  let {
    rootElement,
    productPosition,
    sendSeenEvent = true
  } = _temp === void 0 ? {} : _temp;
  const isFullyVisible = useElementInViewport({
    elementRef: rootElement
  });
  const {
    isSnowplowTrackingEnabled,
    sendTrackEvent,
    updateSnowplowContextData
  } = useSnowplowTracking();
  const location = useLocation();
  const pageName = getTitleFromPath(location.pathname);
  const {
    currencySymbol
  } = (_window$spa_variables = window.spa_variables.priceFormat) !== null && _window$spa_variables !== void 0 ? _window$spa_variables : {};
  const commonProperties = useMemo(() => ({
    category: pageName,
    label: getProductNameForTracking(productName)
  }), [pageName, productName]);
  const shouldSendTracking = isSnowplowTrackingEnabled && Boolean(commonProperties.label);
  const updateContext = useCallback(function (_temp2) {
    let {
      quantity = null,
      price,
      position
    } = _temp2 === void 0 ? {} : _temp2;
    if (!isSnowplowTrackingEnabled) {
      return undefined;
    }
    return new Promise(resolve => {
      setTimeout(() => {
        const extractedPrice = price || getProductPrice(rootElement);
        updateSnowplowContextData({
          productData: {
            ancillaryProduct: {
              name: commonProperties.label,
              position: productPosition || position,
              quantity,
              price: extractedPrice ? cleanText(extractedPrice) : null,
              currency: currencySymbol
            }
          }
        });
        resolve();
      }, 0);
    });
  }, [rootElement, currencySymbol, isSnowplowTrackingEnabled, updateSnowplowContextData, productPosition, commonProperties.label]);
  const sendProductSeenEvent = useCallback(async function (args) {
    if (args === void 0) {
      args = {};
    }
    const {
      availability,
      property,
      value
    } = args;
    if (shouldSendTracking) {
      await updateContext();
      sendTrackEvent({
        action: TRACKING_ACTIONS.ITEM_VIEW,
        ...commonProperties,
        ...(Boolean(availability) && {
          property: availability
        }),
        ...(Boolean(property) && {
          property
        }),
        ...(Boolean(value) && {
          value
        })
      });
    }
  }, [commonProperties, sendTrackEvent, shouldSendTracking, updateContext]);
  const sendClickEvent = useCallback(function (args) {
    if (args === void 0) {
      args = {};
    }
    const {
      value = '',
      property = '',
      label
    } = args;
    if (shouldSendTracking) {
      sendTrackEvent({
        action: TRACKING_ACTIONS.CLICK,
        ...commonProperties,
        ...(Boolean(label) && {
          label
        }),
        value,
        property
      });
    }
  }, [commonProperties, sendTrackEvent, shouldSendTracking]);
  const addTrackingEventOnLinks = useCallback(elementRef => {
    var _ref2;
    const anchorTags = (_ref2 = rootElement || elementRef) === null || _ref2 === void 0 || (_ref2 = _ref2.current) === null || _ref2 === void 0 ? void 0 : _ref2.querySelectorAll('a');
    anchorTags === null || anchorTags === void 0 || anchorTags.forEach(anchor => {
      anchor.addEventListener('click', event => {
        event.stopPropagation();
        sendClickEvent({
          property: 'Terms and Conditions'
        });
      }, {
        once: true
      });
    });
  }, [sendClickEvent, rootElement]);
  const sendSelectEvent = useCallback(function (args) {
    if (args === void 0) {
      args = {};
    }
    const {
      isSelected = true,
      label = getProductNameForTracking(productName),
      value = '',
      property = TRACKING_PROPERTIES.ON_PAGE
    } = args;
    if (shouldSendTracking) {
      sendTrackEvent({
        action: isSelected ? TRACKING_ACTIONS.SELECT : TRACKING_ACTIONS.DE_SELECT,
        ...commonProperties,
        label,
        value,
        property
      });
    }
  }, [commonProperties, productName, sendTrackEvent, shouldSendTracking]);
  const sendAddToCartEvent = useCallback(function (args) {
    if (args === void 0) {
      args = {};
    }
    const {
      addToCart = true,
      label,
      property = TRACKING_PROPERTIES.ON_PAGE,
      value = ''
    } = args;
    if (shouldSendTracking) {
      sendTrackEvent({
        action: addToCart ? TRACKING_ACTIONS.ADD_TO_CART : TRACKING_ACTIONS.SELECT_NONE,
        ...commonProperties,
        ...(Boolean(label) && {
          label
        }),
        value,
        property
      });
    }
  }, [commonProperties, sendTrackEvent, shouldSendTracking]);
  const sendPopupViewEvent = useCallback(() => {
    if (shouldSendTracking) {
      sendTrackEvent({
        action: TRACKING_ACTIONS.POP_UP_VIEW,
        property: TRACKING_PROPERTIES.VALIDATION_POP_UP,
        ...commonProperties
      });
    }
  }, [commonProperties, sendTrackEvent, shouldSendTracking]);
  useEffect(() => {
    if (isFullyVisible && sendSeenEvent) {
      // To add product seen event by default to the product which uses useProductSnowplowTracking hook
      // However, we can omit the behaviour by passing options.sendSeenEvent as false
      sendProductSeenEvent();
    }
  }, [isFullyVisible, sendSeenEvent, sendProductSeenEvent]);

  // To add tracking to the links of abstract section
  useEffect(() => {
    if (shouldSendTracking) {
      addTrackingEventOnLinks();
    }
  }, [shouldSendTracking, addTrackingEventOnLinks]);
  return {
    isFullyVisible,
    sendProductSeenEvent,
    sendClickEvent,
    sendAddToCartEvent,
    addTrackingEventOnLinks,
    sendPopupViewEvent,
    sendSelectEvent,
    updateContext
  };
};