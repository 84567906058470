import { constants } from '@eti/utils';
import groupArray from 'group-array';
import isPlainObj from 'is-plain-obj';
import { RebookEligibility } from '../pages/order-details/components/manage-trip/rebooking/models';

const getTravelers = (travelers, travelerPrices) =>
  travelers.map((traveler, index) => {
    const { price, taxesAndFees } =
      travelerPrices.find((travelerPrice) => travelerPrice.travelerId === traveler.id) || {};

    if (!isPlainObj(price)) {
      return {
        ...traveler,
        id: traveler.id || String(index),
        markup: 0,
        price: 0,
        vat: 0,
        taxesAndFees: [],
      };
    }

    return {
      ...traveler,
      id: traveler.id || String(index),
      markup: price.markup && price.markup.value,
      price: price.price.value,
      vat: price.vat && price.vat.value,
      taxesAndFees,
    };
  });

export const getTravelerPrices = (travelers = [], travelerPrices = []) => {
  const travelerGroups = groupArray(getTravelers(travelers, travelerPrices), 'ageType');

  return Object.entries(travelerGroups).map(([ageType, items]) => ({
    ageType,
    markup: items[0].markup,
    price: items[0].price - items[0].vat - items[0].markup,
    quantity: items.length,
    taxesAndFees: items[0].taxesAndFees || [],
    totalPrice: items[0].price,
    totalPriceSum: items.reduce((total, { price }) => total + price, 0),
    vat: items[0].vat,
  }));
};

export const getTravelerPricesTotal = (travelerPrices = []) =>
  travelerPrices.reduce((total, { totalPriceSum }) => total + totalPriceSum, 0);

const { ageTypes } = constants;

export const getAgeTypeLabelForQuantity = (ageType, quantity = 0) => {
  const ageTypeLabels = {
    [ageTypes.ADULT]:
      quantity > 1 ? 'Air.Search.NumberOfAdultsPlural' : 'Air.Search.NumberOfAdultsSingular',
    [ageTypes.CHILD]:
      quantity > 1 ? 'Air.Search.NumberOfChildrenPlural' : 'Air.Search.NumberOfChildrenSingular',
    [ageTypes.INFANT]:
      quantity > 1 ? 'Air.Search.NumberOfInfantsPlural' : 'Air.Search.NumberOfInfantsSingular',
  };

  return ageTypeLabels[ageType] || ageTypeLabels[ageTypes.ADULT];
};

export const getAgeTypeLabel = (ageType) => {
  const ageTypeLabels = {
    [ageTypes.ADULT]: 'Adult',
    [ageTypes.CHILD]: 'Child',
    [ageTypes.INFANT]: 'Infant',
  };

  return ageTypeLabels[ageType] || ageTypeLabels[ageTypes.ADULT];
};

const getRebookingTravelers = (travelers, travelerPrices, rebookEligibilityType) =>
  travelers.map((traveler, index) => {
    const { rebookingPrice } =
      travelerPrices.find((travelerPrice) => travelerPrice.travelerId === traveler.id) || {};

    if (!isPlainObj(rebookingPrice)) {
      return {
        ...traveler,
        id: traveler.id || String(index),
        airlineFees: 0,
        airlinePenalty: 0,
        fareDifference: 0,
        serviceFee: 0,
        taxDifference: 0,
        totalDifference: 0,
      };
    }

    return {
      ...traveler,
      id: traveler.id || String(index),
      airlineFees: rebookingPrice?.airlineFees?.value,
      airlinePenalty:
        rebookEligibilityType === RebookEligibility.CHANGE_FLEXIBLE_TICKET_YOURSELF
          ? 0
          : rebookingPrice?.airlinePenalty?.value,
      fareDifference: rebookingPrice?.fareDifference?.value,
      serviceFee:
        rebookEligibilityType === RebookEligibility.CHANGE_FLEXIBLE_TICKET_YOURSELF
          ? 0
          : rebookingPrice?.serviceFee?.value,
      taxDifference: rebookingPrice?.taxDifference?.value,
      totalDifference:
        rebookEligibilityType === RebookEligibility.CHANGE_FLEXIBLE_TICKET_YOURSELF
          ? (rebookingPrice?.totalDifference?.value || 0) -
            (rebookingPrice?.serviceFee?.value || 0) -
            (rebookingPrice?.airlinePenalty?.value || 0)
          : rebookingPrice?.totalDifference?.value,
    };
  });

export const getRebookingTravelerPrices = (
  travelers = [],
  travelerPrices = [],
  rebookEligibilityType,
) => {
  const travelerGroups = groupArray(
    getRebookingTravelers(travelers, travelerPrices, rebookEligibilityType),
    'ageType',
  );

  return Object.entries(travelerGroups).map(([ageType, items]) => ({
    ageType,
    airlineFees: items[0].airlineFees,
    airlinePenalty: items[0].airlinePenalty,
    fareDifference: items[0].fareDifference,
    quantity: items.length,
    serviceFee: items[0].serviceFee,
    taxDifference: items[0].taxDifference,
    totalDifference: items[0].totalDifference,
  }));
};
