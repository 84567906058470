import PropTypes from 'prop-types';

export const ID = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const BigDecimal = PropTypes.any

export const DateString = PropTypes.any

export const Long = PropTypes.number

export const AddToCartResponseType = PropTypes.oneOf(['SUCCESS','VALIDATION_FAILED']);

export const AgeType = PropTypes.oneOf(['ADT','CNN','INF']);

export const AuthenticationType = PropTypes.oneOf(['AGENCY_ACTIVATION','FACEBOOK','GOOGLE','ORDER_INFO']);

export const BaggageStatus = PropTypes.oneOf(['ADDED_CHECKED_BAGGAGE','INCLUDED_CHECKED_BAGGAGE','INFANT_NO_CHECKED_BAGGAGE','NO_CHECKED_BAGGAGE','NO_CHECKED_BAGGAGE_AVAILABLE']);

export const BestPriceCategory = PropTypes.oneOf(['HIGH','LOW','MEDIUM','NOT_AVAILABLE']);

export const BindingResponse = PropTypes.oneOf(['SUCCESS','UNAUTHORIZED','UNAVAILABLE']);

export const BrandedFareAvailabilityType = PropTypes.oneOf(['INCLUDED','NOT_INCLUDED','SELLABLE','UNKNOWN']);

export const CabinBaggageStatus = PropTypes.oneOf(['ADDED_CABIN_BAGGAGE','INCLUDED_AND_ADDED_CABIN_BAGGAGE','INCLUDED_CABIN_BAGGAGE','INFANT_NO_CABIN_BAGGAGE','NO_CABIN_BAGGAGE','NO_CABIN_BAGGAGE_AVAILABLE']);

export const CabinClass = PropTypes.oneOf(['BUSINESS','ECONOMY','FIRST','PREMIUM_BUSINESS','PREMIUM_ECONOMY','PREMIUM_FIRST']);

export const CancelReason = PropTypes.oneOf(['FRAUD_NO_REFUND']);

export const CancellationRefundOfferReason = PropTypes.oneOf(['CANCEL_FOR_ANY_REASON','SCHEDULE_CHANGE_REFUND','TICKET_RULES','VOID']);

export const CartState = PropTypes.oneOf(['AWAITING_BOOKING_DETAILS_UPDATE','AWAITING_CUSTOMER_PAYMENT','AWAITING_EXTERNAL_PAYMENT_VERIFICATION','AWAITING_MANUAL_FRAUD_REVIEW','AWAITING_MOP_CART_PAYMENT','AWAITING_PARTNER_PAYMENT','CANCELED','EXTERNAL_FLIGHT_VERIFICATION','MANUAL_BOOKING','ORDER_PENDING','PENDING_PROVIDER_BOOKING','PENDING_PROVIDER_REBOOKING','PRICE_CHANGE','SHOPPING','TIMED_OUT']);

export const CartType = PropTypes.oneOf(['ADD_ON','MODIFICATION_PAYMENT_CART','MOP_CART','PRIMARY']);

export const ChangeReason = PropTypes.oneOf(['CANCEL_PART_OF_ORDER']);

export const CheckInStatusErrorCode = PropTypes.oneOf(['GENERIC_ERROR','REGISTRATION_NOT_FOUND']);

export const DeckType = PropTypes.oneOf(['LOWER_DECK','MAIN_DECK','UPPER_DECK']);

export const DeckTypeInput = PropTypes.oneOf(['LOWER_DECK','MAIN_DECK','UPPER_DECK']);

export const EventSegmentType = PropTypes.oneOf(['CHANGE_OF_AIRPORT','ONE_DAY_STOPOVER','ONE_NIGHT_STOPOVER','OVERNIGHT_STAY','SELF_TRANSFER','SHORT_STOPOVER','STOP','TRANSFER_TO_AIRPORT','TRANSFER_TO_BUS','TRANSFER_TO_TRAIN','TWO_NIGHTS_STOPOVER']);

export const FilterOptionType = PropTypes.oneOf(['AMOUNT','DURATION','NUMBER','TEXT','TIME']);

export const FilterType = PropTypes.oneOf(['AIRLINES','CHECKED_BAGGAGE_INCLUDED','EXCLUDE_SELF_TRANSFER','MAX_STOPS','PRICE','STOPOVER_TYPES','TRAVEL_TIME','TRIP_0_ARRIVAL_TIME','TRIP_0_DEPARTURE_TIME','TRIP_1_ARRIVAL_TIME','TRIP_1_DEPARTURE_TIME','TRIP_2_ARRIVAL_TIME','TRIP_2_DEPARTURE_TIME','TRIP_3_ARRIVAL_TIME','TRIP_3_DEPARTURE_TIME','TRIP_4_ARRIVAL_TIME','TRIP_4_DEPARTURE_TIME']);

export const GenderInput = PropTypes.oneOf(['FEMALE','MALE']);

export const LoginSource = PropTypes.oneOf(['CONTACT_US_LOGIN','ORDER_LOGIN','POSTBOOKING_LOGIN','RYANAIR_LOGIN']);

export const LogoutUserResponsePayload = PropTypes.oneOf(['SUCCESS','SYSTEM_ERROR']);

export const OrderState = PropTypes.oneOf(['CANCELED','CHANGED','INITIAL','UNCHANGED','UNKNOWN']);

export const OrderStatusState = PropTypes.oneOf(['CONFIRMED','FAILED','PROCESSING']);

export const PartOfExternalFlights = PropTypes.oneOf(['ALL','NONE','SOME']);

export const PassengerLegStatus = PropTypes.oneOf(['FAILED','MISSED','PENDING','SUCCESS','SUSPENDED']);

export const PaymentMethodType = PropTypes.oneOf(['APM','BANK','CARD','WALLET']);

export const PaymentResponseType = PropTypes.oneOf(['EXTERNAL_FLIGHT_VERIFICATION','PAYMENT_PAGE_WITH_MESSAGE','PAYMENT_PENDING_EXTERNAL_ACTION','PAYMENT_REDIRECT','PRICE_CHANGE','PROCESSING_ORDER','RECEIPT']);

export const PaymentStatus = PropTypes.oneOf(['COMPLETED','ERROR','PENDING']);

export const PersonalItemStatus = PropTypes.oneOf(['INCLUDED_PERSONAL_ITEM','INFANT_NO_PERSONAL_ITEM','NO_PERSONAL_ITEM']);

export const ProductPreSelection = PropTypes.oneOf(['NONE','NO_SELECTED','YES_SELECTED']);

export const ProviderBookingResult = PropTypes.oneOf(['REJECTED_AIRLINE_COVID_19_POLICY','REJECTED_AIRLINE_DECLARED_BANKRUPTCY','REJECTED_AIRLINE_DECLARED_FINANCIAL_RECONSTRUCTION','REJECTED_AIRLINE_REFUNDED_CUSTOMER_DIRECTLY','REJECTED_AIRLINE_SUSPENDED','REJECTED_BY_AIRLINE','REJECTED_CHARGE_BACK','REJECTED_MEDICAL_REASON','REJECTED_NO_RESPONSE_FROM_AIRLINE','REJECTED_OTHER','REJECTED_REFUND_LOWER_THAN_FEE','SUCCESS']);

export const RebookEligibilityReason = PropTypes.oneOf(['CARRIER_NOT_SUPPORTED','ELIGIBLE','EXCHANGED_BY_AIRLINE','FARE_RESTRICTION','MULTISTOP_NOT_SUPPORTED','NOT_FULLY_TICKETED','NOT_OPEN_TICKET','PRICE_REVIEW_DEACTIVATED','RESTRICTED_CARRIER','SEGMENT_STATUS_NOT_CONFIRMED','SHORT_DEPARTURE_TRIP','SYSTEM_NOT_SUPPORTED','UNSUPPORTED_BY_AIR_SYSTEM']);

export const RebookEligibilityStatus = PropTypes.oneOf(['CHANGE_NOT_AVAILABLE','CHANGE_PNR_BY_AIRLINE','CHANGE_PNR_BY_CUSTOMER','CHANGE_PNR_BY_ETG']);

export const RefundCaseCancelReason = PropTypes.oneOf(['ANCILLARY_VOLUNTARY','BEFORE_TICKETING','CANCELLATION_FOR_ANY_REASON','CANCELLATION_GUARANTEE','CANCELLATION_GUARANTEE_PREMIUM','CANCELLATION_PROTECTION_ETG','CHARGEBACK','CHARGEBACK_CLAIM','CHARGEBACK_NO_REFUND','CONNECTION_GUARANTEE','COVID_19_NO_CUSTOMER_CONTACT','FORCE_MAJEURE_REFUND','FRAUD','FRAUD_BEFORE_DEPARTURE','FRAUD_NO_REFUND','GDPR_WAIVER','INTERNAL_REFUND','LOCK_YOUR_PRICE','MANUAL_ESCALATION','ORPHANED_FUNDS','SCHEDULE_CHANGE_REFUND','TECH_ERROR_ANCILLARY','TECH_ERROR_TICKET','TICKET_RULES_AIRLINE_FEE','TICKET_RULES_NO_REFUND_TO_CUSTOMER','TICKET_RULES_TAXES','TWENTY_FOUR_HOUR_AIRLINE_RULE','UNDEFINED','UNKNOWN','VOID_REASON','VOLUNTARY_GDS']);

export const RefundCaseStep = PropTypes.oneOf(['CUSTOMER_REQUESTED_REFUND_FROM_PROVIDER','MONEY_PAYED_OUT_TO_CUSTOMER','MONEY_RECEIVED_FROM_PROVIDER','REFUND_REQUEST_RECEIVED_FROM_CUSTOMER','REQUESTED_REFUND_FROM_PROVIDER']);

export const ResponseUsage = PropTypes.oneOf(['CACHE_POPULATION','CROSS_SELL','CUSTOMER_DRIVEN_AUTOMATION','CUSTOMER_SALES_PROCESS','ONLINE_MARKETING','PRICE_ALERT','PRICE_COMPARISON']);

export const SearchLocationType = PropTypes.oneOf(['AIRPORT','CITY']);

export const SeatCharacteristic = PropTypes.oneOf(['BASSINET','BULKHEAD','ECONOMY_COMFORT','ELECTRONIC_CONNECTION','FRONT_OF_CABIN','LEG_SPACE','NORMAL','NOT_SUITABLE_FOR_CHILDREN','NO_INFANT_ALLOWED','SUITABLE_FOR_ADT_WITH_INF']);

export const SeatPreferenceSelection = PropTypes.oneOf(['AISLE','BESIDE','WINDOW']);

export const SeatPreferenceSelectionInput = PropTypes.oneOf(['AISLE','BESIDE','WINDOW']);

export const SendEmailResponse = PropTypes.oneOf(['FAILURE','SUCCESS']);

export const SetSettingsResult = PropTypes.oneOf(['REDIRECT','SUCCESS']);

export const SettingsLocaleDirectionality = PropTypes.oneOf(['LTR','RTL']);

export const SettingsLocaleType = PropTypes.oneOf(['ADDITIONAL','PRIMARY']);

export const SortType = PropTypes.oneOf(['BEST_VALUE','CARRIER_PROMO','CHEAP_TRIP','LONG_STAY','RECOMMENDATION','SHORT_TRIP']);

export const Target = PropTypes.oneOf(['_blank','_self']);

export const TaxCategoryType = PropTypes.oneOf(['AIR_COST','FARE','THIRD_PARTY','UNKNOWN']);

export const Tier = PropTypes.oneOf(['BASIC','DELUXE','PREMIUM','RECOMMENDED']);

export const TimeOfDay = PropTypes.oneOf(['AFTERNOON','ANY','MORNING','NOON']);

export const TravelerDetailsTravelDocumentExpirationDate = PropTypes.oneOf(['NOT_REQUIRED','OPTIONAL','REQUIRED']);

export const TripCharacteristics = PropTypes.oneOf(['ONE_WAY_COMBINATION','VALID_WITH_VOUCHER','VIRTUAL_INTERLINING','VIRTUAL_INTERLINING_WITH_SHORT_CONNECTION_TIME']);

export const TripTag = PropTypes.oneOf(['PRICE','SPEED','SPONSOR','STAY','WEIGHTED']);

export const TripType = PropTypes.oneOf(['MULTI_STOP','ONE_WAY','OPEN_JAW_DOUBLE','OPEN_JAW_SINGLE_DESTINATION','OPEN_JAW_SINGLE_ORIGIN','RETURN']);

export const faqType = PropTypes.oneOf(['APP','DEFAULT','DESTINATION','MY_PORTAL','ORIGIN_DESTINATION','SEO_ANCILLARY']);

export const status = PropTypes.oneOf(['NOT_ACCEPTED','SUCCESS','UNKNOWN']);

export const AddToAddonCartResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const AddToCartResponse = PropTypes.shape({
  message: PropTypes.string,
  success: PropTypes.bool,
  type: AddToCartResponseType,
});

export const AgencyAccount = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

export const AncillaryBarcodes = PropTypes.shape({
  barcodeData: PropTypes.string,
  barcodeFormat: PropTypes.string,
  travelerReference: Long,
});

export const AncillaryPdfs = PropTypes.shape({
  pdfId: PropTypes.string,
  travelerReference: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string,
});

export const AncillaryProduct = PropTypes.shape({
  ancillaryBarcodes: PropTypes.arrayOf(AncillaryBarcodes),
  ancillaryPdfs: PropTypes.arrayOf(AncillaryPdfs),
  code: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
});

export const ApisInfo = PropTypes.shape({
  description: PropTypes.string,
  title: PropTypes.string,
});

export const ApplePayAdditionalData = PropTypes.shape({
  code: PropTypes.string,
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  label: PropTypes.string,
  merchantCapabilities: PropTypes.arrayOf(PropTypes.string),
  merchantIdentifier: PropTypes.string,
  supportedNetworks: PropTypes.arrayOf(PropTypes.string),
  totalAmount: PropTypes.string,
});

export const AvailablePaymentInstalmentOption = PropTypes.shape({
  baseCostPerInstalment: BigDecimal,
  installmentOptionId: Long,
  numberOfInstalments: PropTypes.number,
  ratePercentage: BigDecimal,
});

export const BankDataAdyen = PropTypes.shape({
  brandCode: PropTypes.string,
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  issuerId: PropTypes.string,
  merchantAccount: PropTypes.string,
  merchantReference: PropTypes.string,
  merchantReturnData: PropTypes.string,
  merchantSig: PropTypes.string,
  paymentAmount: PropTypes.string,
  resURL: PropTypes.string,
  sessionValidity: PropTypes.string,
  shipBeforeDate: PropTypes.string,
  shopperLocale: PropTypes.string,
  skinCode: PropTypes.string,
});

export const BankDataDummy = PropTypes.shape({
  bankRedirectURL: PropTypes.string,
});

export const BankDataOgone = PropTypes.shape({
  bankRedirectURL: PropTypes.string,
});

export const BankData = PropTypes.oneOfType([BankDataAdyen,BankDataDummy,BankDataOgone]);

export const BankPaymentMethodTexts = PropTypes.shape({
  descriptionText: PropTypes.string,
});

export const BestPriceBound = PropTypes.shape({
  date: PropTypes.string,
  destination: PropTypes.string,
  origin: PropTypes.string,
});

export const BestPriceCharacteristic = PropTypes.shape({
  characteristic: PropTypes.string,
  price: Long,
});

export const BestPricePerDay = PropTypes.shape({
  date: PropTypes.string,
  price: PropTypes.number,
  priceCategory: BestPriceCategory,
});

export const BestPricePerPeriod = PropTypes.shape({
  bounds: PropTypes.arrayOf(BestPriceBound),
  period: PropTypes.number,
  price: PropTypes.number,
});

export const BestPricesPerDay = PropTypes.shape({
  bestPricesPerDay: PropTypes.arrayOf(BestPricePerDay),
});

export const BestPricesPerPeriod = PropTypes.shape({
  bestPricesPerPeriod: PropTypes.arrayOf(BestPricePerPeriod),
});

export const BindOrderToSessionResponse = PropTypes.shape({
  brandCodeForRealSite: PropTypes.string,
  brandNameForRealSite: PropTypes.string,
  hostNameForRealSite: PropTypes.string,
  response: BindingResponse,
});

export const Brand = PropTypes.shape({
  code: PropTypes.string,
  id: Long,
  name: PropTypes.string,
});

export const BrandInformationResponse = PropTypes.shape({
  brandNameForActualSite: PropTypes.string,
  urlForActualSite: PropTypes.string,
});

export const BrandedFareFeature = PropTypes.shape({
  availability: BrandedFareAvailabilityType,
  code: PropTypes.string,
  group: PropTypes.string,
  name: PropTypes.string,
});

export const BrandedFareInfo = PropTypes.shape({
  features: PropTypes.arrayOf(BrandedFareFeature),
  id: ID,
  name: PropTypes.string,
});

export const BundleSelection = PropTypes.shape({
  bundleId: ID,
  discountPercentage: Long,
  optionHeading: PropTypes.string,
  optionId: ID,
});

export const CabinBaggageSelection = PropTypes.shape({
  travelerId: ID,
});

export const Cadillac = PropTypes.shape({
  filename: PropTypes.string,
  siteKey: PropTypes.string,
  url: PropTypes.string,
});

export const CancelMakeOrderResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const CancelOrderWithRefundOfferResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const CancellationRefundOfferProduct = PropTypes.shape({
  amount: Long,
  code: PropTypes.string,
  id: ID,
  name: PropTypes.string,
  productSummaryAlternativeName: PropTypes.string,
});

export const CarrierBookingNumber = PropTypes.shape({
  bookingNumber: PropTypes.string,
  carrierCode: PropTypes.string,
  carrierName: PropTypes.string,
});

export const CarrierConditions = PropTypes.shape({
  description: PropTypes.string,
  url: PropTypes.string,
});

export const CarrierNew = PropTypes.shape({
  code: PropTypes.string,
  conditions: CarrierConditions,
  name: PropTypes.string,
});

export const CarrierInfo = PropTypes.shape({
  carrier: CarrierNew,
  description: PropTypes.string,
  title: PropTypes.string,
});

export const CellPhone = PropTypes.shape({
  countryId: PropTypes.number,
  phoneNumberAfterPrefix: PropTypes.string,
  prefixId: ID,
});

export const CheckedBaggage = PropTypes.shape({
  pieces: PropTypes.number,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const ClientSideEncryption3DSecureData = PropTypes.shape({
  md: PropTypes.string,
  paReq: PropTypes.string,
  termUrl: PropTypes.string,
});

export const ClientSideEncryptionPaymentFlow = PropTypes.shape({
  generationTime: PropTypes.string,
  publicKey: PropTypes.string,
  type: PropTypes.string,
});

export const ConfigurationContext = PropTypes.shape({
  highlightProduct: PropTypes.bool,
  persuasionEnabled: PropTypes.bool,
  showPricePerBound: PropTypes.bool,
  showPricePerTraveler: PropTypes.bool,
});

export const Configuration = PropTypes.shape({
  postbookingAncillaryStore: ConfigurationContext,
  postbookingPayment: ConfigurationContext,
  productPreSelection: ProductPreSelection,
  travelerDetails: ConfigurationContext,
});

export const ContactInformation = PropTypes.shape({
  cellPhone: CellPhone,
  email: PropTypes.string,
});

export const ContactUsDetails = PropTypes.shape({
  englishSchedule: PropTypes.string,
  englishScheduleTitle: PropTypes.string,
  localSchedule: PropTypes.string,
  localScheduleTitle: PropTypes.string,
  operatorClarification: PropTypes.string,
  phoneNumber: PropTypes.string,
  scheduleDescription: PropTypes.string,
  scheduleException: PropTypes.string,
});

export const Currency = PropTypes.shape({
  code: PropTypes.string,
  exponent: PropTypes.number,
  name: PropTypes.string,
});

export const CurrencyAmount = PropTypes.shape({
  currency: Currency,
  value: Long,
});

export const AncillaryCarrierPriceBreakdown = PropTypes.shape({
  carrier: CarrierNew,
  providerPrice: CurrencyAmount,
});

export const Bank = PropTypes.shape({
  code: PropTypes.string,
  id: ID,
  name: PropTypes.string,
  price: CurrencyAmount,
  serviceFee: CurrencyAmount,
});

export const BankPaymentMethod = PropTypes.shape({
  banks: PropTypes.arrayOf(Bank),
  paymentServiceProvider: PropTypes.string,
  texts: BankPaymentMethodTexts,
  type: PropTypes.string,
});

export const CancellationRefundOffer = PropTypes.shape({
  airlineFee: Long,
  expirationDateTimeUTC: PropTypes.string,
  expires: Long,
  handlingFee: Long,
  nonRefundableProducts: PropTypes.arrayOf(CancellationRefundOfferProduct),
  refundOfferDescription: PropTypes.string,
  refundOfferDetailedDescription: PropTypes.string,
  refundOfferId: Long,
  refundOfferReason: CancellationRefundOfferReason,
  refundOfferTitle: PropTypes.string,
  refundableAmount: Long,
  refundableAmountInChargingCurrency: CurrencyAmount,
  totalAmountForNonRefundableProducts: Long,
});

export const CreditCard = PropTypes.shape({
  availablePaymentInstallmentOptions: PropTypes.arrayOf(AvailablePaymentInstalmentOption),
  code: PropTypes.string,
  id: Long,
  name: PropTypes.string,
  price: CurrencyAmount,
  serviceFee: CurrencyAmount,
});

export const DateFormat = PropTypes.shape({
  date: PropTypes.string,
  datetime: PropTypes.string,
  day: PropTypes.string,
  dayDdmmm: PropTypes.string,
  dayDdmmmTime: PropTypes.string,
  dayName: PropTypes.string,
  dayTime: PropTypes.string,
  dayYear: PropTypes.string,
  longMonthYear: PropTypes.string,
  monthYear: PropTypes.string,
  shortDayShortMonth: PropTypes.string,
  shortWeekday: PropTypes.string,
  shortWeekdayFullYear: PropTypes.string,
  shortWeekdayYear: PropTypes.string,
  time: PropTypes.string,
  timeWeekdayYear: PropTypes.string,
  weekday: PropTypes.string,
  weekdayYear: PropTypes.string,
});

export const Descriptions = PropTypes.shape({
  text: PropTypes.string,
  type: PropTypes.string,
});

export const DiscountErrorMessage = PropTypes.shape({
  headerText: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
});

export const ETicket = PropTypes.shape({
  ticketNumber: PropTypes.string,
  travelerId: ID,
});

export const EmailValidationResponse = PropTypes.shape({
  message: PropTypes.string,
  valid: PropTypes.bool,
});

export const EmergencyPhone = PropTypes.shape({
  countryId: PropTypes.number,
  phoneNumberAfterPrefix: PropTypes.string,
  prefixId: ID,
});

export const EmergencyContactInformation = PropTypes.shape({
  lastName: PropTypes.string,
  phoneNumber: EmergencyPhone,
});

export const EmptyAddOnCart = PropTypes.shape({
  expectedState: PropTypes.string,
  failReason: PropTypes.string,
  noAccessReason: PropTypes.arrayOf(PropTypes.string),
  state: PropTypes.string,
  success: PropTypes.bool,
});

export const EventSegment = PropTypes.shape({
  arrivalTime: PropTypes.string,
  arrivedAt: Long,
  departureTime: PropTypes.string,
  departuredAt: Long,
  description: PropTypes.string,
  duration: Long,
  id: ID,
  types: PropTypes.arrayOf(EventSegmentType),
});

export const ExternalFlightVerificationInfoSuppliers = PropTypes.shape({
  carrierCodes: PropTypes.arrayOf(PropTypes.string),
  lccSupplier: PropTypes.string,
});

export const ExternalFlightVerificationLocale = PropTypes.shape({
  countryCode: PropTypes.string,
  languageCode: PropTypes.string,
});

export const ExternalFlightVerificationInfo = PropTypes.shape({
  isExternalFlightVerified: PropTypes.bool,
  locale: ExternalFlightVerificationLocale,
  suppliers: PropTypes.arrayOf(ExternalFlightVerificationInfoSuppliers),
});

export const ExtraProductTexts = PropTypes.shape({
  agentNotes: PropTypes.string,
  legalInformation: PropTypes.string,
  name: PropTypes.string,
  productSummaryAlternativeName: PropTypes.string,
  readMoreText: PropTypes.string,
  receiptText: PropTypes.string,
  salesAbstract: PropTypes.string,
  smallCardText: PropTypes.string,
  upsellTextWhenBooked: PropTypes.string,
});

export const FareRule = PropTypes.shape({
  content: PropTypes.string,
  header: PropTypes.string,
});

export const FilterOption = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const FooterIconUrl = PropTypes.shape({
  href: PropTypes.string,
});

export const FooterIcon = PropTypes.shape({
  id: PropTypes.string,
  url: FooterIconUrl,
});

export const FrequentFlyer = PropTypes.shape({
  frequentFlyerCardId: PropTypes.number,
  frequentFlyerCardNumber: PropTypes.string,
});

export const FrequentFlyerCardType = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
});

export const GeoPoint = PropTypes.shape({
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const GooglePayAdditionalData = PropTypes.shape({
  allowedCardNetworks: PropTypes.arrayOf(PropTypes.string),
  code: PropTypes.string,
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  gateway: PropTypes.string,
  gatewayMerchantId: PropTypes.string,
  merchantCapabilities: PropTypes.arrayOf(PropTypes.string),
  merchantId: PropTypes.string,
  totalAmount: PropTypes.string,
});

export const IncludedCheckedBaggage = PropTypes.shape({
  pieces: PropTypes.number,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const IncludedExtraProduct = PropTypes.shape({
  id: ID,
  texts: ExtraProductTexts,
});

export const InvoicePdf = PropTypes.shape({
  pdfData: PropTypes.string,
});

export const Language = PropTypes.shape({
  code: PropTypes.string,
  id: Long,
  locale: PropTypes.string,
  name: PropTypes.string,
});

export const Links = PropTypes.shape({
  baggageInformation: PropTypes.string,
});

export const Localization = PropTypes.shape({
  availableLanguages: PropTypes.arrayOf(Language),
});

export const LogoutUserResponse = PropTypes.shape({
  response: LogoutUserResponsePayload,
});

export const ModifyProductSelectionResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const MutuallyExclusive = PropTypes.shape({
  productTypes: PropTypes.arrayOf(Long),
});

export const Notification = PropTypes.shape({
  header: PropTypes.string,
  isImportant: PropTypes.bool,
  text: PropTypes.string,
});

export const OrderCreationSiteInfo = PropTypes.shape({
  brandCode: PropTypes.string,
  brandName: PropTypes.string,
  hostName: PropTypes.string,
  marketCode: PropTypes.string,
  orderUrl: PropTypes.string,
});

export const OrderInformation = PropTypes.shape({
  header: PropTypes.string,
  text: PropTypes.string,
});

export const OrderStatus = PropTypes.shape({
  state: OrderStatusState,
});

export const OrderStatusFailed = PropTypes.shape({
  state: OrderStatusState,
});

export const PassengerInformation = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  passengerId: PropTypes.string,
});

export const PassengerLegInformation = PropTypes.shape({
  passengerId: PropTypes.string,
  status: PassengerLegStatus,
});

export const LegInformation = PropTypes.shape({
  airlineLocator: PropTypes.string,
  arrivalAirport: PropTypes.string,
  arrivalCity: PropTypes.string,
  departureAirport: PropTypes.string,
  departureCity: PropTypes.string,
  departureDate: PropTypes.string,
  departureTime: PropTypes.string,
  passengerLegs: PropTypes.arrayOf(PassengerLegInformation),
});

export const CheckInStatus = PropTypes.shape({
  errorCode: CheckInStatusErrorCode,
  legs: PropTypes.arrayOf(LegInformation),
  passengers: PropTypes.arrayOf(PassengerInformation),
});

export const PayInternalPaymentMethod = PropTypes.shape({
  paymentServiceProvider: PropTypes.string,
  type: PropTypes.string,
});

export const Payment3DSecureData = PropTypes.shape({
  md: PropTypes.string,
  paReq: PropTypes.string,
  termUrl: PropTypes.string,
});

export const PaymentAdditionalData = PropTypes.shape({
  code: PropTypes.string,
});

export const PaymentFlow = PropTypes.shape({
  type: PropTypes.string,
});

export const PaymentInstalmentOption = PropTypes.shape({
  baseCostPerInstalment: BigDecimal,
  feePerInstalment: BigDecimal,
  installmentOptionId: Long,
  isCostPaidByCustomer: PropTypes.bool,
  isEtgCharges: PropTypes.bool,
  numberOfInstalments: PropTypes.number,
  paymentProvider: PropTypes.string,
  ratePercentage: BigDecimal,
  totalCostAfterFees: BigDecimal,
  totalCostPerInstalment: BigDecimal,
  totalFees: BigDecimal,
});

export const InstalmentOptions = PropTypes.shape({
  paymentInstalmentOptions: PropTypes.arrayOf(PaymentInstalmentOption),
  shouldAddInstallmentFeeInTotalAmount: PropTypes.bool,
});

export const PaymentMethod = PropTypes.shape({
  paymentServiceProvider: PropTypes.string,
  type: PropTypes.string,
});

export const PaymentMethodIssuer = PropTypes.shape({
  id: Long,
  name: PropTypes.string,
});

export const PaymentMethodPrice = PropTypes.shape({
  code: PropTypes.string,
  id: ID,
  name: PropTypes.string,
  price: CurrencyAmount,
  type: PaymentMethodType,
});

export const PaymentStatusResponse = PropTypes.shape({
  status: PaymentStatus,
});

export const PhoneNumberStructured = PropTypes.shape({
  countryCallingCode: PropTypes.string,
  numberWithoutCountryCode: PropTypes.string,
});

export const ContactDetails = PropTypes.shape({
  email: PropTypes.string,
  phone: PhoneNumberStructured,
});

export const CustomerTravelSeller = PropTypes.shape({
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PhoneNumberStructured,
});

export const PhoneNumberValidationResponse = PropTypes.shape({
  message: PropTypes.string,
  valid: PropTypes.bool,
});

export const PhonePrefix = PropTypes.shape({
  id: ID,
  prefix: PropTypes.string,
});

export const PostArg = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
});

export const AddonPaymentResponse = PropTypes.shape({
  makeOrderProcessId: PropTypes.string,
  message: PropTypes.string,
  postArgs: PropTypes.arrayOf(PostArg),
  redirectURL: PropTypes.string,
  success: PropTypes.bool,
  type: PaymentResponseType,
});

export const Price = PropTypes.shape({
  markup: CurrencyAmount,
  price: CurrencyAmount,
  vat: CurrencyAmount,
});

export const CartSeatDetails = PropTypes.shape({
  boundIndex: PropTypes.number,
  price: Price,
  seat: PropTypes.string,
  segmentIndex: PropTypes.number,
});

export const CartTravelerSeatMapDetails = PropTypes.shape({
  ancillaryCarrierPriceBreakdowns: PropTypes.arrayOf(AncillaryCarrierPriceBreakdown),
  price: Price,
  seatDetails: PropTypes.arrayOf(CartSeatDetails),
  travelerId: ID,
});

export const PaymentProduct = PropTypes.shape({
  cardSubType: PropTypes.string,
  code: PropTypes.string,
  id: ID,
  maskedCardNumber: PropTypes.string,
  name: PropTypes.string,
  price: Price,
  productSummaryAlternativeName: PropTypes.string,
  type: PropTypes.string,
});

export const PriceFormat = PropTypes.shape({
  currencyExponent: PropTypes.number,
  currencySymbol: PropTypes.string,
  decimalSymbol: PropTypes.string,
  groupingSeparator: PropTypes.string,
  pattern: PropTypes.string,
});

export const ChargingCurrency = PropTypes.shape({
  currency: Currency,
  priceFormat: PriceFormat,
  value: PropTypes.number,
});

export const PriceRange = PropTypes.shape({
  max: Long,
  min: Long,
});

export const ProviderBooking = PropTypes.shape({
  description: PropTypes.string,
  lastUpdatedUTC: PropTypes.string,
  reference: PropTypes.string,
  title: PropTypes.string,
});

export const ProviderBookingBound = PropTypes.shape({
  destination: PropTypes.string,
  origin: PropTypes.string,
});

export const ProviderBookingStepInformation = PropTypes.shape({
  completed: PropTypes.bool,
  description: PropTypes.string,
  isVisible: PropTypes.bool,
  step: RefundCaseStep,
  title: PropTypes.string,
});

export const PspCascadingTransitionData = PropTypes.shape({
  md: PropTypes.string,
  paReq: PropTypes.string,
  pspCascadingRedirectUrl: PropTypes.string,
  termUrl: PropTypes.string,
});

export const PaymentData = PropTypes.shape({
  availablePaymentMethods: PropTypes.arrayOf(PaymentMethod),
  cadillac: Cadillac,
  disablePanAndDoB: PropTypes.bool,
  pspCascadingTransition: PspCascadingTransitionData,
  transactionId: PropTypes.string,
});

export const QPassText = PropTypes.shape({
  arrivalAirport: PropTypes.string,
  departureAirport: PropTypes.string,
  departureDateTime: PropTypes.string,
  flightNumber: PropTypes.string,
});

export const QPassTexts = PropTypes.shape({
  QPassText: PropTypes.arrayOf(QPassText),
});

export const QuickSortPrices = PropTypes.shape({
  bestValue: CurrencyAmount,
  carrierPromo: CurrencyAmount,
  cheapTrip: CurrencyAmount,
  longStay: CurrencyAmount,
  recommendation: CurrencyAmount,
  shortTrip: CurrencyAmount,
});

export const RangeFilter = PropTypes.shape({
  code: PropTypes.string,
  group: PropTypes.string,
  label: PropTypes.string,
  max: Long,
  min: Long,
  type: FilterOptionType,
});

export const Rebook = PropTypes.shape({
  message: PropTypes.string,
  success: PropTypes.bool,
});

export const RebookEligibilityPnrInfo = PropTypes.shape({
  flexibleTicketRemainsApplicable: PropTypes.bool,
  hasAirlineAncillaries: PropTypes.bool,
  pnr: PropTypes.string,
});

export const PnrEligibility = PropTypes.shape({
  info: RebookEligibilityPnrInfo,
  reason: RebookEligibilityReason,
  status: RebookEligibilityStatus,
});

export const RebookEligibilityPerPnrResponse = PropTypes.shape({
  hasFlexibleTicket: PropTypes.bool,
  pnrEligibilityList: PropTypes.arrayOf(PnrEligibility),
});

export const RebookEligibilityResponse = PropTypes.shape({
  message: PropTypes.string,
  rebookFlow: PropTypes.string,
});

export const RebookingPrice = PropTypes.shape({
  airlineFees: CurrencyAmount,
  airlinePenalty: CurrencyAmount,
  fareDifference: CurrencyAmount,
  serviceFee: CurrencyAmount,
  taxDifference: CurrencyAmount,
  totalDifference: CurrencyAmount,
});

export const RebookingTravelerPrice = PropTypes.shape({
  id: ID,
  rebookingPrice: RebookingPrice,
  travelerId: ID,
});

export const ReceiptPdf = PropTypes.shape({
  pdf: PropTypes.string,
});

export const RefundCaseProviderBooking = PropTypes.shape({
  bounds: PropTypes.arrayOf(ProviderBookingBound),
  carrier: PropTypes.string,
  carrierCode: PropTypes.string,
  cc2Carrier: PropTypes.bool,
  description: PropTypes.string,
  hasAcceptedVoucher: PropTypes.bool,
  journeyType: TripType,
  lastUpdatedUTC: PropTypes.string,
  payoutDelayed: PropTypes.bool,
  providerBookingResult: ProviderBookingResult,
  reference: PropTypes.string,
  steps: PropTypes.arrayOf(ProviderBookingStepInformation),
  title: PropTypes.string,
});

export const RefundCaseRelatedProviderBooking = PropTypes.shape({
  description: PropTypes.string,
  lastUpdatedUTC: PropTypes.string,
  reference: PropTypes.string,
  title: PropTypes.string,
});

export const RefundPaymentMethod = PropTypes.shape({
  maskedCardNumber: PropTypes.string,
  paymentSubType: PropTypes.string,
  paymentType: PropTypes.string,
});

export const RefundedProduct = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  refundedAmount: CurrencyAmount,
});

export const RefundedProviderBooking = PropTypes.shape({
  bounds: PropTypes.arrayOf(ProviderBookingBound),
  journeyType: TripType,
  reference: PropTypes.string,
  refundedAmount: CurrencyAmount,
});

export const RefundPayout = PropTypes.shape({
  manuallyCalculatedRefundAmount: CurrencyAmount,
  refundHandlingFee: CurrencyAmount,
  refundNonBankAddition: CurrencyAmount,
  refundPaymentMethod: RefundPaymentMethod,
  refundedProducts: PropTypes.arrayOf(RefundedProduct),
  refundedProductsTotalAmount: CurrencyAmount,
  refundedProviderBookings: PropTypes.arrayOf(RefundedProviderBooking),
  totalRefundedAmount: CurrencyAmount,
});

export const RefundCaseInformation = PropTypes.shape({
  cancelReason: RefundCaseCancelReason,
  closed: PropTypes.bool,
  providerBookings: PropTypes.arrayOf(ProviderBooking),
  refundPayouts: PropTypes.arrayOf(RefundPayout),
});

export const RefundStatusInformation = PropTypes.shape({
  cases: PropTypes.arrayOf(RefundCaseInformation),
  description: PropTypes.string,
  hasChargeback: PropTypes.bool,
  refundedCasesTotalAmount: CurrencyAmount,
  title: PropTypes.string,
});

export const RegionNew = PropTypes.shape({
  airportCode: PropTypes.string,
  airportName: PropTypes.string,
  cityCode: PropTypes.string,
  cityName: PropTypes.string,
  code: PropTypes.string,
  continentCode: PropTypes.string,
  continentName: PropTypes.string,
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
  geoPoint: GeoPoint,
  isAirport: PropTypes.bool,
  name: PropTypes.string,
  timezoneId: PropTypes.string,
});

export const OriginalSegment = PropTypes.shape({
  arrivedAt: PropTypes.string,
  departuredAt: PropTypes.string,
  destination: RegionNew,
  flightNumber: PropTypes.string,
  marketingCarrier: CarrierNew,
  origin: RegionNew,
});

export const OriginalVersion = PropTypes.shape({
  addedSegments: PropTypes.arrayOf(PropTypes.number),
  originalSegments: PropTypes.arrayOf(OriginalSegment),
  removedSegments: PropTypes.arrayOf(PropTypes.number),
});

export const Reissue = PropTypes.shape({
  message: PropTypes.string,
  success: PropTypes.bool,
});

export const RejectPriceChangeResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const RemoveOrderFromSessionResponse = PropTypes.shape({
  response: BindingResponse,
});

export const ResultFilter = PropTypes.shape({
  code: PropTypes.string,
  group: PropTypes.string,
  label: PropTypes.string,
  type: FilterOptionType,
});

export const Rule = PropTypes.shape({
  description: PropTypes.string,
  title: PropTypes.string,
});

export const SearchLocation = PropTypes.shape({
  city: PropTypes.string,
  cityCode: PropTypes.string,
  continent: PropTypes.string,
  continentCode: PropTypes.string,
  country: PropTypes.string,
  countryCode: PropTypes.string,
  geoPoint: GeoPoint,
  iataCode: PropTypes.string,
  id: Long,
  multipleAirports: PropTypes.bool,
  name: PropTypes.string,
  state: PropTypes.string,
  timeZoneId: PropTypes.string,
  type: SearchLocationType,
});

export const SearchMetaData = PropTypes.shape({
  searchPath: PropTypes.string,
  selectionKey: PropTypes.string,
  tripId: PropTypes.number,
});

export const SearchTraveler = PropTypes.shape({
  age: PropTypes.number,
  ageType: AgeType,
});

export const SeatMapSegmentTexts = PropTypes.shape({
  boundIndex: PropTypes.number,
  segmentIndex: PropTypes.number,
  text: PropTypes.string,
});

export const SeatMapTexts = PropTypes.shape({
  seatMapSegmentTexts: PropTypes.arrayOf(SeatMapSegmentTexts),
});

export const SeatingSeatMapSelection = PropTypes.shape({
  boundIndex: PropTypes.number,
  column: PropTypes.string,
  deckType: DeckType,
  row: PropTypes.number,
  seatPreference: SeatPreferenceSelection,
  segmentIndex: PropTypes.number,
  travelerId: ID,
});

export const SecondBagSummary = PropTypes.shape({
  baggageDetails: CheckedBaggage,
  travelerId: PropTypes.string,
});

export const SecondBagTripSummary = PropTypes.shape({
  secondBagSummaries: PropTypes.arrayOf(SecondBagSummary),
});

export const Segment = PropTypes.shape({
  ancillaryProducts: PropTypes.arrayOf(AncillaryProduct),
  id: PropTypes.number,
});

export const Bound = PropTypes.shape({
  id: PropTypes.number,
  segments: PropTypes.arrayOf(Segment),
});

export const AncillaryPasses = PropTypes.shape({
  bounds: PropTypes.arrayOf(Bound),
});

export const SegmentDetailsNew = PropTypes.shape({
  numberOfSeatsLeft: PropTypes.number,
  paxType: PropTypes.string,
});

export const SelectLanguageResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const SelectionFilter = PropTypes.shape({
  code: PropTypes.string,
  group: PropTypes.string,
  isMultiChoice: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(FilterOption),
  type: FilterOptionType,
});

export const SelfServiceRebookingTraveler = PropTypes.shape({
  ageType: PropTypes.string,
  birthDate: PropTypes.string,
  firstName: PropTypes.string,
  gender: PropTypes.string,
  id: ID,
  lastName: PropTypes.string,
});

export const SellPriceBaggageNew = PropTypes.shape({
  ancillaryCarrierPriceBreakdowns: PropTypes.arrayOf(AncillaryCarrierPriceBreakdown),
  maxWeight: PropTypes.number,
  numberOfUnits: PropTypes.number,
  price: Price,
  travelerId: ID,
  weightUnit: PropTypes.string,
});

export const SellPriceInsuranceNew = PropTypes.shape({
  description: PropTypes.string,
  disclaimer: PropTypes.string,
  id: ID,
  inclusions: PropTypes.arrayOf(Rule),
  persuasionEnabled: PropTypes.bool,
  price: Price,
  title: PropTypes.string,
});

export const SellPriceTravelerNew = PropTypes.shape({
  ancillaryCarrierPriceBreakdowns: PropTypes.arrayOf(AncillaryCarrierPriceBreakdown),
  price: Price,
  travelerId: ID,
});

export const ChoiceNew = PropTypes.shape({
  descriptions: PropTypes.arrayOf(Descriptions),
  id: ID,
  name: PropTypes.string,
  pricePerTravelers: PropTypes.arrayOf(SellPriceTravelerNew),
});

export const SellSeatMapColumnDetailsNew = PropTypes.shape({
  columnName: PropTypes.string,
  type: PropTypes.string,
});

export const SellSeatMapSeatNew = PropTypes.shape({
  characteristics: PropTypes.arrayOf(SeatCharacteristic),
  column: PropTypes.string,
  isBookable: PropTypes.bool,
  mainCharacteristic: SeatCharacteristic,
  price: Price,
  row: PropTypes.number,
  seat: PropTypes.string,
  type: PropTypes.string,
});

export const SellSeatMapRowNew = PropTypes.shape({
  characteristics: PropTypes.arrayOf(PropTypes.string),
  rowNumber: PropTypes.number,
  sellSeatMapSeats: PropTypes.arrayOf(SellSeatMapSeatNew),
});

export const SellSeatMapCabinNew = PropTypes.shape({
  designatedClass: PropTypes.string,
  rows: PropTypes.arrayOf(SellSeatMapRowNew),
  sellSeatMapColumnDetails: PropTypes.arrayOf(SellSeatMapColumnDetailsNew),
});

export const SellSeatMapDeckNew = PropTypes.shape({
  cabins: PropTypes.arrayOf(SellSeatMapCabinNew),
});

export const SellSeatMapSegmentNew = PropTypes.shape({
  bestPriceCharacteristics: PropTypes.arrayOf(BestPriceCharacteristic),
  id: ID,
  lowerDeck: SellSeatMapDeckNew,
  mainDeck: SellSeatMapDeckNew,
  travelerIds: PropTypes.arrayOf(ID),
  upperDeck: SellSeatMapDeckNew,
});

export const SellSeatingBeside = PropTypes.shape({
  id: ID,
  price: Price,
});

export const SellSeatingPreference = PropTypes.shape({
  id: ID,
  price: Price,
});

export const SellSeatMap = PropTypes.oneOfType([SellSeatMapSegmentNew,SellSeatingBeside,SellSeatingPreference]);

export const SellSeatMapBoundNew = PropTypes.shape({
  id: ID,
  sellSeatMapSegments: PropTypes.arrayOf(SellSeatMap),
});

export const SellSpecificationBaggageNew = PropTypes.shape({
  sellPriceBaggage: PropTypes.arrayOf(SellPriceBaggageNew),
});

export const SellSpecificationInsuranceNew = PropTypes.shape({
  numberOfTravelers: PropTypes.number,
  sellPriceInsurances: PropTypes.arrayOf(SellPriceInsuranceNew),
  termsUrl: PropTypes.string,
});

export const SellSpecificationNoneNew = PropTypes.shape({
  price: Price,
});

export const SellSpecificationSeatingSeatMapNew = PropTypes.shape({
  sellSeatMapBounds: PropTypes.arrayOf(SellSeatMapBoundNew),
});

export const SellSpecificationTravelerChoiceNew = PropTypes.shape({
  availableChoices: PropTypes.arrayOf(ChoiceNew),
  preselectedChoice: PropTypes.string,
  pricePerTravelers: PropTypes.arrayOf(SellPriceTravelerNew),
});

export const SellSpecificationTravelerNew = PropTypes.shape({
  sellPriceTravelers: PropTypes.arrayOf(SellPriceTravelerNew),
});

export const SendChallengeEmailResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const SettingsCurrency = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

export const SettingsLocaleRegion = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

export const SettingsLocale = PropTypes.shape({
  availableCurrencies: PropTypes.arrayOf(SettingsCurrency),
  code: PropTypes.string,
  directionality: SettingsLocaleDirectionality,
  name: PropTypes.string,
  region: SettingsLocaleRegion,
  type: SettingsLocaleType,
});

export const SettingsRegion = PropTypes.shape({
  availableLocales: PropTypes.arrayOf(SettingsLocale),
  code: PropTypes.string,
  name: PropTypes.string,
});

export const SetSettingsResponse = PropTypes.shape({
  redirectUrl: PropTypes.string,
  result: SetSettingsResult,
  selectedCurrency: SettingsCurrency,
  selectedLocale: SettingsLocale,
  selectedRegion: SettingsRegion,
});

export const Settings = PropTypes.shape({
  regions: PropTypes.arrayOf(SettingsRegion),
  selectedCurrency: SettingsCurrency,
  selectedLocale: SettingsLocale,
  selectedRegion: SettingsRegion,
});

export const Size3d = PropTypes.shape({
  height: PropTypes.number,
  length: PropTypes.number,
  width: PropTypes.number,
});

export const CabinBaggageDetails = PropTypes.shape({
  pieces: PropTypes.number,
  size3d: Size3d,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const CabinBaggageTravelerDetails = PropTypes.shape({
  cabinBaggageDetails: CabinBaggageDetails,
  cabinBaggageStatus: CabinBaggageStatus,
  travelerId: PropTypes.string,
});

export const CabinBaggageSegmentSummary = PropTypes.shape({
  cabinBaggageTravelerDetails: PropTypes.arrayOf(CabinBaggageTravelerDetails),
  segmentId: ID,
});

export const CabinBaggageBoundSummary = PropTypes.shape({
  boundId: ID,
  cabinBaggageSegmentSummaries: PropTypes.arrayOf(CabinBaggageSegmentSummary),
  cabinBaggageTravelerDetails: PropTypes.arrayOf(CabinBaggageTravelerDetails),
});

export const CabinBaggageTripSummary = PropTypes.shape({
  cabinBaggageBoundSummaries: PropTypes.arrayOf(CabinBaggageBoundSummary),
  cabinBaggageTravelerDetails: PropTypes.arrayOf(CabinBaggageTravelerDetails),
});

export const Content = PropTypes.shape({
  code: PropTypes.string,
  description: PropTypes.string,
  included: PropTypes.bool,
  legalInformation: PropTypes.string,
  name: PropTypes.string,
  pieces: PropTypes.number,
  productTypeId: ID,
  readMoreText: PropTypes.string,
  size3d: Size3d,
  termsUrl: PropTypes.string,
  upsellTextWhenBooked: PropTypes.string,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const IncludedCabinBaggage = PropTypes.shape({
  includedPersonalItem: PropTypes.bool,
  pieces: PropTypes.number,
  size3d: Size3d,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const Option = PropTypes.shape({
  content: PropTypes.arrayOf(Content),
  id: ID,
  name: PropTypes.string,
  originalPrice: Price,
  price: Price,
  tier: Tier,
});

export const BundleProduct = PropTypes.shape({
  discountPercentage: PropTypes.number,
  id: ID,
  locationRestrictionId: PropTypes.number,
  name: PropTypes.string,
  options: PropTypes.arrayOf(Option),
  selectedOption: ID,
});

export const PersonalItemDetails = PropTypes.shape({
  size3d: Size3d,
});

export const PersonalItemTravelerDetails = PropTypes.shape({
  personalItemDetails: PersonalItemDetails,
  personalItemStatus: PersonalItemStatus,
  travelerId: PropTypes.string,
});

export const PersonalItemSegmentSummary = PropTypes.shape({
  personalItemTravelerDetails: PropTypes.arrayOf(PersonalItemTravelerDetails),
  segmentId: ID,
});

export const PersonalItemBoundSummary = PropTypes.shape({
  boundId: ID,
  personalItemSegmentSummaries: PropTypes.arrayOf(PersonalItemSegmentSummary),
  personalItemTravelerDetails: PropTypes.arrayOf(PersonalItemTravelerDetails),
});

export const PersonalItemTripSummary = PropTypes.shape({
  personalItemBoundSummaries: PropTypes.arrayOf(PersonalItemBoundSummary),
  personalItemTravelerDetails: PropTypes.arrayOf(PersonalItemTravelerDetails),
});

export const SellCabinBaggageNew = PropTypes.shape({
  pieces: PropTypes.number,
  sellPriceTravelers: PropTypes.arrayOf(SellPriceTravelerNew),
  size3d: Size3d,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const SellSpecificationNew = PropTypes.oneOfType([SellCabinBaggageNew,SellSpecificationBaggageNew,SellSpecificationInsuranceNew,SellSpecificationNoneNew,SellSpecificationSeatingSeatMapNew,SellSpecificationTravelerChoiceNew,SellSpecificationTravelerNew]);

export const SortTypeOption = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

export const State = PropTypes.shape({
  code: PropTypes.string,
  id: ID,
  name: PropTypes.string,
});

export const Country = PropTypes.shape({
  code: PropTypes.string,
  id: Long,
  name: PropTypes.string,
  phonePrefixes: PropTypes.arrayOf(PhonePrefix),
  states: PropTypes.arrayOf(State),
});

export const CustomerDetails = PropTypes.shape({
  city: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  nationality: Country,
  phone: PropTypes.string,
  street: PropTypes.string,
  zipCode: PropTypes.string,
});

export const Step = PropTypes.shape({
  completed: PropTypes.bool,
  name: PropTypes.string,
  stepNumber: PropTypes.number,
});

export const StepCompletionResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const SubMenuItem = PropTypes.shape({
  name: PropTypes.string,
  path: PropTypes.string,
  target: Target,
});

export const MainMenuItem = PropTypes.shape({
  menuType: PropTypes.string,
  name: PropTypes.string,
  path: PropTypes.string,
  subMenu: PropTypes.arrayOf(SubMenuItem),
});

export const Header = PropTypes.shape({
  mainMenuItems: PropTypes.arrayOf(MainMenuItem),
});

export const Menu = PropTypes.shape({
  mainMenuItems: PropTypes.arrayOf(MainMenuItem),
});

export const Footer = PropTypes.shape({
  legal: PropTypes.string,
  menus: PropTypes.arrayOf(Menu),
  paymentProviders: PropTypes.arrayOf(FooterIcon),
  siteFlags: PropTypes.arrayOf(FooterIcon),
  trustedPartners: PropTypes.arrayOf(FooterIcon),
});

export const SeoData = PropTypes.shape({
  mainMenuItems: PropTypes.arrayOf(MainMenuItem),
});

export const SiteInformation = PropTypes.shape({
  footer: Footer,
  header: Header,
});

export const Summary = PropTypes.shape({
  baggageDetails: CheckedBaggage,
  baggageStatus: BaggageStatus,
  travelerId: PropTypes.string,
});

export const SegmentSummary = PropTypes.shape({
  segmentId: ID,
  summaries: PropTypes.arrayOf(Summary),
});

export const BoundSummary = PropTypes.shape({
  boundId: ID,
  segmentSummaries: PropTypes.arrayOf(SegmentSummary),
  summaries: PropTypes.arrayOf(Summary),
});

export const CheckedBaggageSummary = PropTypes.shape({
  boundSummaries: PropTypes.arrayOf(BoundSummary),
  tripSummaries: PropTypes.arrayOf(Summary),
});

export const TaxOrFee = PropTypes.shape({
  amount: CurrencyAmount,
  code: PropTypes.string,
  title: PropTypes.string,
});

export const TaxCategory = PropTypes.shape({
  category: TaxCategoryType,
  taxes: PropTypes.arrayOf(TaxOrFee),
});

export const CarrierPriceBreakdown = PropTypes.shape({
  carrier: CarrierNew,
  providerPrice: CurrencyAmount,
  taxesAndFees: PropTypes.arrayOf(TaxCategory),
  vat: CurrencyAmount,
});

export const TechnicalStop = PropTypes.shape({
  arrivalAt: PropTypes.string,
  departureAt: PropTypes.string,
  duration: Long,
  location: RegionNew,
});

export const Thunderbird = PropTypes.shape({
  enabled: PropTypes.bool,
  publicKey: PropTypes.string,
});

export const CardPaymentMethod = PropTypes.shape({
  binVerificationType: PropTypes.string,
  creditCards: PropTypes.arrayOf(CreditCard),
  paymentFlow: PaymentFlow,
  paymentServiceProvider: PropTypes.string,
  potentialChargeInCurrencies: PropTypes.arrayOf(PropTypes.string),
  potentialChargeInDifferentCurrency: PropTypes.bool,
  thunderbird: Thunderbird,
  type: PropTypes.string,
});

export const TokenizationPaymentFlow = PropTypes.shape({
  tokenizationUrl: PropTypes.string,
  type: PropTypes.string,
});

export const TransactionBreakdownEntry = PropTypes.shape({
  carrierName: PropTypes.string,
  transactionAmount: Price,
});

export const TravelDocumentInformation = PropTypes.shape({
  birthDate: PropTypes.string,
  cpfNumber: PropTypes.string,
  expiryDate: PropTypes.string,
  issueCity: PropTypes.string,
  issueDate: PropTypes.string,
  nationalityId: ID,
  travelDocumentNumber: PropTypes.string,
});

export const DBFTraveler = PropTypes.shape({
  ageType: AgeType,
  birthDate: PropTypes.string,
  firstName: PropTypes.string,
  frequentFlyerCard: FrequentFlyer,
  gender: GenderInput,
  id: ID,
  lastName: PropTypes.string,
  travelDocumentInformation: TravelDocumentInformation,
});

export const TravelTimeRange = PropTypes.shape({
  max: PropTypes.number,
  min: PropTypes.number,
});

export const ResultSetMetaData = PropTypes.shape({
  marketingCarriers: PropTypes.arrayOf(CarrierNew),
  priceRange: PriceRange,
  travelTimeRange: TravelTimeRange,
});

export const TravelerChoice = PropTypes.shape({
  choiceId: ID,
  travelerId: ID,
});

export const TravelerDetailsTravelDocument = PropTypes.shape({
  requireTravelDocumentExpiryDate: TravelerDetailsTravelDocumentExpirationDate,
  showTravelDocumentCpfNumber: PropTypes.bool,
  showTravelDocumentDateOfBirth: PropTypes.bool,
  showTravelDocumentExpiryDate: PropTypes.bool,
  showTravelDocumentIssueCity: PropTypes.bool,
  showTravelDocumentIssueDate: PropTypes.bool,
  showTravelDocumentNationality: PropTypes.bool,
  showTravelDocumentPassportNumber: PropTypes.bool,
});

export const TravelerDetailsTraveler = PropTypes.shape({
  age: PropTypes.number,
  ageType: PropTypes.string,
  id: PropTypes.string,
  showFirstNameBeforeLastName: PropTypes.bool,
  showFrequentFlyerCard: PropTypes.bool,
  travelDocument: TravelerDetailsTravelDocument,
});

export const TravelerDetails = PropTypes.shape({
  searchMetaData: SearchMetaData,
  showEmergencyContactInformation: PropTypes.bool,
  travelers: PropTypes.arrayOf(TravelerDetailsTraveler),
});

export const TravelerInformationResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const TravelerNumberOfUnits = PropTypes.shape({
  numberOfUnits: PropTypes.number,
  travelerId: ID,
});

export const ExtraProductSelection = PropTypes.shape({
  productId: ID,
  selectionCabinBaggage: PropTypes.arrayOf(CabinBaggageSelection),
  selectionNone: PropTypes.bool,
  selectionSeatMap: PropTypes.arrayOf(SeatingSeatMapSelection),
  selectionTraveler: PropTypes.arrayOf(ID),
  selectionTravelerChoice: PropTypes.arrayOf(TravelerChoice),
  selectionTravelerNumberOfUnits: PropTypes.arrayOf(TravelerNumberOfUnits),
});

export const TravelerPrice = PropTypes.shape({
  carrierPriceBreakdowns: PropTypes.arrayOf(CarrierPriceBreakdown),
  id: ID,
  price: Price,
  taxesAndFees: PropTypes.arrayOf(TaxCategory),
  travelerId: ID,
});

export const TripCampaign = PropTypes.shape({
  campaignImageUrlDesktop: PropTypes.string,
  campaignImageUrlMobile: PropTypes.string,
  selectionKey: PropTypes.string,
});

export const TripPriceChange = PropTypes.shape({
  id: ID,
  priceDiff: Price,
  priceNew: Price,
  priceOld: Price,
});

export const TripRoute = PropTypes.shape({
  departureAt: PropTypes.string,
  departureDate: PropTypes.string,
  departureTimeOfDay: TimeOfDay,
  destination: RegionNew,
  origin: RegionNew,
});

export const TripRouteInformation = PropTypes.shape({
  arrivedAt: PropTypes.string,
  departuredAt: PropTypes.string,
  destination: PropTypes.string,
  destinationCityCode: PropTypes.string,
  origin: PropTypes.string,
  originCityCode: PropTypes.string,
});

export const FlightInformation = PropTypes.shape({
  carrier: CarrierNew,
  externalFlightVerificationInfo: ExternalFlightVerificationInfo,
  isEligibleForScheduleChangeRefund: PropTypes.bool,
  loginEmail: PropTypes.string,
  pnr: PropTypes.string,
  segments: PropTypes.arrayOf(TripRouteInformation),
  url: PropTypes.string,
});

export const OrderInfo = PropTypes.shape({
  bounds: PropTypes.arrayOf(TripRouteInformation),
  email: PropTypes.string,
  isUpsellAllowed: PropTypes.bool,
  journeyType: TripType,
  numberOfTravelers: Long,
  orderNumber: PropTypes.string,
  orderState: OrderState,
  originalSite: OrderCreationSiteInfo,
});

export const TripScheduleChange = PropTypes.shape({
  arrivalTimeNew: PropTypes.string,
  arrivalTimeOld: PropTypes.string,
  arrivedAtNew: Long,
  arrivedAtOld: Long,
  departureTimeNew: PropTypes.string,
  departureTimeOld: PropTypes.string,
  departuredAtNew: Long,
  departuredAtOld: Long,
  id: ID,
  segmentId: ID,
});

export const TripChange = PropTypes.oneOfType([TripPriceChange,TripScheduleChange]);

export const PaymentResponse = PropTypes.shape({
  bankData: BankData,
  clientSideEncryption3DSecureData: ClientSideEncryption3DSecureData,
  makeOrderProcessId: PropTypes.string,
  message: PropTypes.string,
  orderLink: PropTypes.string,
  payment3DSecureData: Payment3DSecureData,
  postArgs: PropTypes.arrayOf(PostArg),
  redirectURL: PropTypes.string,
  success: PropTypes.bool,
  tripChanges: PropTypes.arrayOf(TripChange),
  type: PaymentResponseType,
});

export const UpdateFop = PropTypes.shape({
  message: PropTypes.string,
  success: PropTypes.bool,
});

export const UpdateSelectedTripBeforeReservationResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const User = PropTypes.shape({
  email: PropTypes.string,
  loginOrderNumber: PropTypes.string,
  loginType: AuthenticationType,
  sessionOrderNumber: PropTypes.string,
});

export const AuthenticateUserResponse = PropTypes.shape({
  authenticated: PropTypes.bool,
  brandCodeForActualSite: PropTypes.string,
  brandNameForActualSite: PropTypes.string,
  orderDetailsUrlForActualSite: PropTypes.string,
  redirectionRoute: PropTypes.string,
  urlForActualSite: PropTypes.string,
  user: User,
});

export const UserSelectedProductDetails = PropTypes.shape({
  ancillaryBundleSelection: PropTypes.arrayOf(BundleSelection),
  extraProductSelection: PropTypes.arrayOf(ExtraProductSelection),
  stepNumber: PropTypes.number,
});

export const VerifyEmailChallengeResponse = PropTypes.shape({
  response: BindingResponse,
});

export const VirtualCard = PropTypes.shape({
  cvc: PropTypes.string,
  expMonthYear: PropTypes.string,
  lastFourDigits: PropTypes.string,
});

export const TripSegment = PropTypes.shape({
  aircraftType: PropTypes.string,
  arrivedAt: PropTypes.string,
  bookingClass: PropTypes.string,
  brandedFareInformation: BrandedFareInfo,
  cabinClass: PropTypes.string,
  cabinClassName: PropTypes.string,
  carrierBookingNumber: CarrierBookingNumber,
  departuredAt: PropTypes.string,
  destination: RegionNew,
  duration: Long,
  eTickets: PropTypes.arrayOf(ETicket),
  equipmentCode: PropTypes.string,
  fareBasis: PropTypes.string,
  flightNumber: PropTypes.string,
  id: ID,
  includedCabinBaggage: IncludedCabinBaggage,
  includedCheckedBaggage: IncludedCheckedBaggage,
  lccSupplier: PropTypes.string,
  marketingCarrier: CarrierNew,
  numberOfTechnicalStops: PropTypes.number,
  operatingCarrier: CarrierNew,
  operatingInformation: PropTypes.string,
  origin: RegionNew,
  pnr: PropTypes.string,
  segmentDetails: PropTypes.arrayOf(SegmentDetailsNew),
  technicalStops: PropTypes.arrayOf(TechnicalStop),
  ticketingCarrier: CarrierNew,
  virtualCard: VirtualCard,
});

export const SegmentNew = PropTypes.oneOfType([EventSegment,TripSegment]);

export const BoundNew = PropTypes.shape({
  id: ID,
  includedCabinBaggage: IncludedCabinBaggage,
  includedCheckedBaggage: IncludedCheckedBaggage,
  originalVersion: OriginalVersion,
  segments: PropTypes.arrayOf(SegmentNew),
});

export const VisaCountry = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

export const VisaInfo = PropTypes.shape({
  country: VisaCountry,
  description: PropTypes.string,
  title: PropTypes.string,
});

export const TravelDocsInfo = PropTypes.shape({
  apisInfo: ApisInfo,
  carrierInfo: PropTypes.arrayOf(CarrierInfo),
  visaInfo: PropTypes.arrayOf(VisaInfo),
});

export const Wallet = PropTypes.shape({
  additionalData: PaymentAdditionalData,
  code: PropTypes.string,
  id: ID,
  name: PropTypes.string,
  price: CurrencyAmount,
  serviceFee: CurrencyAmount,
});

export const WalletPaymentMethod = PropTypes.shape({
  paymentServiceProvider: PropTypes.string,
  type: PropTypes.string,
  wallets: PropTypes.arrayOf(Wallet),
});

export const addDiscountCodeResponse = PropTypes.shape({
  discountErrorMessage: DiscountErrorMessage,
  discountPrice: Price,
  success: PropTypes.bool,
});

export const amenity = PropTypes.shape({
  code: PropTypes.string,
  id: Long,
  name: PropTypes.string,
});

export const cartTravelerBaggagePrice = PropTypes.shape({
  boundIndex: PropTypes.number,
  maxWeight: PropTypes.number,
  pieces: PropTypes.number,
  price: Price,
  travelerId: ID,
  weightUnit: PropTypes.string,
});

export const cartTravelerBaggageTripPrice = PropTypes.shape({
  ancillaryCarrierPriceBreakdowns: PropTypes.arrayOf(AncillaryCarrierPriceBreakdown),
  maxWeight: PropTypes.number,
  pieces: PropTypes.number,
  price: Price,
  travelerId: ID,
  weightUnit: PropTypes.string,
});

export const BuySpecificationCartTravelerBaggage = PropTypes.shape({
  travelerBaggagePrices: PropTypes.arrayOf(cartTravelerBaggagePrice),
  travelerBaggageTripPrices: PropTypes.arrayOf(cartTravelerBaggageTripPrice),
});

export const cartTravelerCabinBaggagePrice = PropTypes.shape({
  ancillaryCarrierPriceBreakdowns: PropTypes.arrayOf(AncillaryCarrierPriceBreakdown),
  pieces: PropTypes.number,
  price: Price,
  size3d: Size3d,
  travelerId: ID,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const BuySpecificationCartTravelerCabinBaggage = PropTypes.shape({
  travelerCabinBaggagePrices: PropTypes.arrayOf(cartTravelerCabinBaggagePrice),
});

export const cartTravelerChoicePrice = PropTypes.shape({
  choice: PropTypes.string,
  price: Price,
  travelerId: ID,
});

export const BuySpecificationCartTravelerChoice = PropTypes.shape({
  travelerChoicePrices: PropTypes.arrayOf(cartTravelerChoicePrice),
});

export const cartTravelerPrice = PropTypes.shape({
  price: Price,
  travelerId: ID,
});

export const BuySpecificationCartTraveler = PropTypes.shape({
  travelerPrices: PropTypes.arrayOf(cartTravelerPrice),
});

export const cartTravelerSeatMapPrice = PropTypes.shape({
  boundIndex: PropTypes.number,
  price: Price,
  seat: PropTypes.string,
  segmentIndex: PropTypes.number,
  travelerId: ID,
});

export const BuySpecificationCartTravelerSeatMap = PropTypes.shape({
  travelerSeatMapDetails: PropTypes.arrayOf(CartTravelerSeatMapDetails),
  travelerSeatMapPrices: PropTypes.arrayOf(cartTravelerSeatMapPrice),
});

export const BuySpecificationCart = PropTypes.oneOfType([BuySpecificationCartTraveler,BuySpecificationCartTravelerBaggage,BuySpecificationCartTravelerCabinBaggage,BuySpecificationCartTravelerChoice,BuySpecificationCartTravelerSeatMap]);

export const condition = PropTypes.shape({
  code: PropTypes.string,
  description: PropTypes.string,
});

export const consent = PropTypes.shape({
  createdAt: PropTypes.string,
  domain: PropTypes.string,
  optin: PropTypes.bool,
  reference: PropTypes.string,
  source: PropTypes.string,
  subject: PropTypes.string,
});

export const faqQuestionAndAnswer = PropTypes.shape({
  answer: PropTypes.string,
  isPopularTopic: PropTypes.bool,
  question: PropTypes.string,
  slug: PropTypes.string,
});

export const faqSection = PropTypes.shape({
  questionAndAnswers: PropTypes.arrayOf(faqQuestionAndAnswer),
  slug: PropTypes.string,
  title: PropTypes.string,
});

export const faq = PropTypes.shape({
  faqType: faqType,
  sections: PropTypes.arrayOf(faqSection),
});

export const insuranceProductInformationDocument = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
});

export const InsuranceTexts = PropTypes.shape({
  description: PropTypes.string,
  disclaimer: PropTypes.string,
  inclusions: PropTypes.arrayOf(Rule),
  ipid: insuranceProductInformationDocument,
  termsUrl: PropTypes.string,
  title: PropTypes.string,
});

export const localesAndDomains = PropTypes.shape({
  domain: PropTypes.string,
  languageId: Long,
  languageName: PropTypes.string,
  localeCode: PropTypes.string,
});

export const BrandSiteLocale = PropTypes.shape({
  country: PropTypes.string,
  countryCode: PropTypes.string,
  countryId: Long,
  localesAndDomains: PropTypes.arrayOf(localesAndDomains),
});

export const SiteContext = PropTypes.shape({
  agencyAccount: AgencyAccount,
  brand: Brand,
  brandSiteLocales: PropTypes.arrayOf(BrandSiteLocale),
  dateFormat: DateFormat,
  entryType: PropTypes.string,
  language: Language,
  market: Country,
  partner: PropTypes.string,
  productionWeb: PropTypes.string,
  siteCurrency: Currency,
  siteName: PropTypes.string,
});

export const loungeInfo = PropTypes.shape({
  amenities: PropTypes.arrayOf(amenity),
  closeAt: PropTypes.string,
  conditions: PropTypes.arrayOf(condition),
  loungeName: PropTypes.string,
  openAt: PropTypes.string,
  terminal: PropTypes.string,
});

export const ProductSegmentAvailabilityText = PropTypes.shape({
  arrivalAirport: PropTypes.string,
  available: PropTypes.bool,
  boundIndex: PropTypes.number,
  boundType: PropTypes.string,
  departureAirport: PropTypes.string,
  departureDateTime: PropTypes.string,
  extSystem: Long,
  flightNumber: PropTypes.string,
  loungeInfo: loungeInfo,
  segmentIndex: PropTypes.number,
});

export const ProductSegmentAvailabilityTexts = PropTypes.shape({
  productSegmentAvailabilityTexts: PropTypes.arrayOf(ProductSegmentAvailabilityText),
});

export const ProductSpecificTexts = PropTypes.oneOfType([InsuranceTexts,ProductSegmentAvailabilityTexts,QPassTexts,SeatMapTexts]);

export const ExtraProduct = PropTypes.shape({
  code: PropTypes.string,
  configuration: Configuration,
  id: ID,
  name: PropTypes.string,
  productSpecificTexts: ProductSpecificTexts,
  selectedWithTrip: PropTypes.bool,
  sellSpecification: SellSpecificationNew,
  texts: ExtraProductTexts,
});

export const AncillaryAvailabilityTrip = PropTypes.shape({
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
});

export const AncillaryAvailability = PropTypes.shape({
  trips: PropTypes.arrayOf(AncillaryAvailabilityTrip),
});

export const DynamicBookingFlowStepProducts = PropTypes.shape({
  availableAncillaryBundles: PropTypes.arrayOf(BundleProduct),
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
  mutuallyExclusiveProducts: PropTypes.arrayOf(MutuallyExclusive),
});

export const DynamicBookingFlow = PropTypes.shape({
  stepIndicators: PropTypes.arrayOf(Step),
  stepProducts: DynamicBookingFlowStepProducts,
});

export const ProductNew = PropTypes.shape({
  ancillaryCarrierPriceBreakdowns: PropTypes.arrayOf(AncillaryCarrierPriceBreakdown),
  buySpecification: BuySpecificationCart,
  code: PropTypes.string,
  description: PropTypes.string,
  id: ID,
  name: PropTypes.string,
  price: Price,
  productSpecificTexts: ProductSpecificTexts,
  productSummaryAlternativeName: PropTypes.string,
  readMoreText: PropTypes.string,
  texts: ExtraProductTexts,
  tripId: ID,
});

export const AddOnOrder = PropTypes.shape({
  creationDate: PropTypes.string,
  customerOrderReference: PropTypes.string,
  extraProducts: PropTypes.arrayOf(ProductNew),
  orderNumber: PropTypes.string,
  price: Price,
});

export const CancellationOptions = PropTypes.shape({
  includedCancellationProducts: PropTypes.arrayOf(ProductNew),
  refundOffers: PropTypes.arrayOf(CancellationRefundOffer),
  refundOffersError: PropTypes.string,
});

export const customerProductSelection = PropTypes.shape({
  selectedAncillaryBundles: PropTypes.arrayOf(BundleProduct),
  selectedProducts: PropTypes.arrayOf(ExtraProduct),
  userSelectedProductDetails: PropTypes.arrayOf(UserSelectedProductDetails),
});

export const pageContainer = PropTypes.shape({
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
});

export const pendingProviderBooking = PropTypes.shape({
  customerEmail: PropTypes.string,
  departureAt: PropTypes.string,
  destination: PropTypes.string,
  fullName: PropTypes.string,
  orderNumberToBe: PropTypes.string,
  origin: PropTypes.string,
});

export const removeDiscountsResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const result = PropTypes.shape({
  outcome: PropTypes.bool,
});

export const results = PropTypes.shape({
  boundIndex: PropTypes.number,
  segmentIndex: PropTypes.number,
  status: status,
});

export const frequentFlyerCard = PropTypes.shape({
  cardType: PropTypes.string,
  cardTypeId: ID,
  cardTypeName: PropTypes.string,
  number: PropTypes.string,
  results: PropTypes.arrayOf(results),
});

export const TravelerNew = PropTypes.shape({
  age: PropTypes.number,
  ageType: AgeType,
  birthDate: PropTypes.string,
  contactDetails: ContactDetails,
  firstName: PropTypes.string,
  frequentFlyerCard: frequentFlyerCard,
  fullName: PropTypes.string,
  gender: GenderInput,
  id: ID,
  lastName: PropTypes.string,
  name: PropTypes.string,
  nationality: PropTypes.string,
  passportNumber: PropTypes.string,
});

export const BrandedFare = PropTypes.shape({
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
  cabinBaggageTripSummary: CabinBaggageTripSummary,
  checkedBaggageSummary: CheckedBaggageSummary,
  features: PropTypes.arrayOf(BrandedFareFeature),
  id: ID,
  name: PropTypes.string,
  personalItemTripSummary: PersonalItemTripSummary,
  selectionKey: PropTypes.string,
  travelerPrices: PropTypes.arrayOf(TravelerPrice),
  travelers: PropTypes.arrayOf(TravelerNew),
});

export const RebookingTrip = PropTypes.shape({
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
  bounds: PropTypes.arrayOf(BoundNew),
  id: ID,
  includedCabinBaggage: IncludedCabinBaggage,
  includedCheckedBaggage: IncludedCheckedBaggage,
  paymentMethodPrices: PropTypes.arrayOf(PaymentMethodPrice),
  rebookingTravelerPrices: PropTypes.arrayOf(RebookingTravelerPrice),
  rebookingTravelerPricesWithoutPaymentDiscounts: PropTypes.arrayOf(RebookingTravelerPrice),
  selectionKey: PropTypes.string,
  travelers: PropTypes.arrayOf(TravelerNew),
  tripCharacteristics: PropTypes.arrayOf(TripCharacteristics),
  type: TripType,
});

export const RebookingSearch = PropTypes.shape({
  availableFilters: PropTypes.arrayOf(ResultFilter),
  availableSortTypes: PropTypes.arrayOf(SortTypeOption),
  filteredFlightsCount: PropTypes.number,
  flights: PropTypes.arrayOf(RebookingTrip),
  flightsCount: PropTypes.number,
  hasAirlineAncillaries: PropTypes.bool,
  rebookFlow: PropTypes.string,
  resultSetMetaData: ResultSetMetaData,
  routes: PropTypes.arrayOf(TripRoute),
  searchPath: PropTypes.string,
  travelers: PropTypes.arrayOf(SearchTraveler),
});

export const Trip = PropTypes.shape({
  ancillaryBundles: PropTypes.arrayOf(BundleProduct),
  ancillaryPages: pageContainer,
  availableAncillaryBundles: PropTypes.arrayOf(BundleProduct),
  availableETGFares: PropTypes.arrayOf(BundleProduct),
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
  bounds: PropTypes.arrayOf(BoundNew),
  brandedFares: PropTypes.arrayOf(BrandedFare),
  cabinBaggageTripSummary: CabinBaggageTripSummary,
  changes: PropTypes.arrayOf(TripChange),
  chargingCurrencyExchangeRate: ChargingCurrency,
  checkInReference: PropTypes.string,
  checkedBaggageSummary: CheckedBaggageSummary,
  customer: CustomerTravelSeller,
  dynamicBookingFlow: DynamicBookingFlow,
  extraProducts: PropTypes.arrayOf(ProductNew),
  fareRules: PropTypes.arrayOf(FareRule),
  frequentFlyerCardTypes: PropTypes.arrayOf(FrequentFlyerCardType),
  id: ID,
  includedCabinBaggage: IncludedCabinBaggage,
  includedCheckedBaggage: IncludedCheckedBaggage,
  includedExtraProducts: PropTypes.arrayOf(IncludedExtraProduct),
  isVI: PropTypes.bool,
  mutuallyExclusiveProducts: PropTypes.arrayOf(MutuallyExclusive),
  numberOfAncillaryPages: PropTypes.number,
  paymentMethodPrices: PropTypes.arrayOf(PaymentMethodPrice),
  personalItemTripSummary: PersonalItemTripSummary,
  requestableBrandedFares: PropTypes.bool,
  secondBagTripSummary: SecondBagTripSummary,
  selectionKey: PropTypes.string,
  shareableUrl: PropTypes.string,
  systems: PropTypes.arrayOf(PropTypes.string),
  travelerPrices: PropTypes.arrayOf(TravelerPrice),
  travelerPricesWithoutPaymentDiscounts: PropTypes.arrayOf(TravelerPrice),
  travelers: PropTypes.arrayOf(TravelerNew),
  tripCharacteristics: PropTypes.arrayOf(TripCharacteristics),
  tripId: ID,
  tripTags: PropTypes.arrayOf(TripTag),
  type: TripType,
  voucherAmount: CurrencyAmount,
});

export const Cart = PropTypes.shape({
  ancillaryBundles: PropTypes.arrayOf(BundleProduct),
  chargeCurrencyPriceFormat: PriceFormat,
  customerContactInfo: CustomerTravelSeller,
  customerEmail: PropTypes.string,
  customerPhone: PropTypes.string,
  discountPrice: Price,
  hasPotentialForAdditionalTax: PropTypes.bool,
  id: ID,
  price: Price,
  priceInChargeCurrency: Price,
  products: PropTypes.arrayOf(ProductNew),
  restoreCartUrl: PropTypes.string,
  serviceFee: CurrencyAmount,
  state: CartState,
  subtotalPrice: Price,
  trip: Trip,
  trips: PropTypes.arrayOf(Trip),
});

export const OrderNew = PropTypes.shape({
  addOnOrders: PropTypes.arrayOf(AddOnOrder),
  cancelReason: CancelReason,
  changeReason: ChangeReason,
  chargeCurrencyPriceFormat: PriceFormat,
  consentUrl: PropTypes.string,
  customerDetails: CustomerDetails,
  discountPrice: Price,
  flightInformation: PropTypes.arrayOf(FlightInformation),
  hasBeenChanged: PropTypes.bool,
  instalmentsTotalFee: Long,
  isEligibleForCancellationCertificate: PropTypes.bool,
  isRefunded: PropTypes.bool,
  isUpsellAllowed: PropTypes.bool,
  obfuscatedOrderReference: PropTypes.string,
  oldOrderNumber: PropTypes.string,
  orderInformation: PropTypes.arrayOf(OrderInformation),
  orderNumber: PropTypes.string,
  orderReferenceSerialized: PropTypes.string,
  orderState: OrderState,
  partOfExternalFlights: PartOfExternalFlights,
  paymentProducts: PropTypes.arrayOf(PaymentProduct),
  postBookingLink: PropTypes.string,
  priceInChargeCurrency: Price,
  purchaseDate: PropTypes.string,
  refundStatus: RefundStatusInformation,
  serviceFee: CurrencyAmount,
  shouldAddInstallmentFeeInTotalAmount: PropTypes.bool,
  totalPrice: Long,
  totalPriceIncludingAddOnOrders: Long,
  totalPriceIncludingAddOnOrdersInChargeCurrency: Long,
  transactionBreakdown: PropTypes.arrayOf(TransactionBreakdownEntry),
  travelDocLink: PropTypes.string,
  trips: PropTypes.arrayOf(Trip),
});

export const OrderStatusConfirmed = PropTypes.shape({
  customerDetails: CustomerDetails,
  customerTravelSeller: CustomerTravelSeller,
  isUpsellAllowed: PropTypes.bool,
  obfuscatedOrderReference: PropTypes.string,
  orderNumber: PropTypes.string,
  orderedAt: PropTypes.string,
  state: OrderStatusState,
  trips: PropTypes.arrayOf(Trip),
});

export const OrderStatusProcessing = PropTypes.shape({
  customerDetails: CustomerDetails,
  customerTravelSeller: CustomerTravelSeller,
  isUpsellAllowed: PropTypes.bool,
  orderNumber: PropTypes.string,
  orderedAt: PropTypes.string,
  state: OrderStatusState,
  trips: PropTypes.arrayOf(Trip),
});

export const Search = PropTypes.shape({
  availableFilters: PropTypes.arrayOf(ResultFilter),
  availableSortTypes: PropTypes.arrayOf(SortTypeOption),
  cabinClass: CabinClass,
  carrierCodes: PropTypes.arrayOf(PropTypes.string),
  carrierNames: PropTypes.arrayOf(PropTypes.string),
  direct: PropTypes.bool,
  filteredFlightsCount: PropTypes.number,
  flights: PropTypes.arrayOf(Trip),
  flightsCount: PropTypes.number,
  quickSortPrices: QuickSortPrices,
  responseUsage: ResponseUsage,
  resultSetMetaData: ResultSetMetaData,
  routes: PropTypes.arrayOf(TripRoute),
  searchPath: PropTypes.string,
  sponsoredTrips: PropTypes.arrayOf(Trip),
  travelers: PropTypes.arrayOf(SearchTraveler),
  tripCampaigns: PropTypes.arrayOf(TripCampaign),
  type: TripType,
  validWithVoucher: PropTypes.bool,
});

export const SelectTripResponse = PropTypes.shape({
  selectedTrip: Trip,
  success: PropTypes.bool,
});

export const selfServiceRebooking = PropTypes.shape({
  criteria: PropTypes.arrayOf(PropTypes.string),
  travelers: PropTypes.arrayOf(SelfServiceRebookingTraveler),
  validatingCarriers: PropTypes.arrayOf(PropTypes.string),
});

export const sendPostBookingEmailToGenesysResponse = PropTypes.shape({
  response: SendEmailResponse,
});

export const MutationRoot = PropTypes.shape({
  addConsents: result,
  addDiscountCode: addDiscountCodeResponse,
  addToAddonCart: AddToAddonCartResponse,
  addToCart: AddToCartResponse,
  authenticateUser: AuthenticateUserResponse,
  bindOrderToSession: BindOrderToSessionResponse,
  cancelMakeOrder: CancelMakeOrderResponse,
  cancelOrderWithRefundOffer: CancelOrderWithRefundOfferResponse,
  cancellationOptions: CancellationOptions,
  completeStep: StepCompletionResponse,
  continueAddonOrderWithCardPayment: AddonPaymentResponse,
  continueAddonOrderWithWalletPayment: AddonPaymentResponse,
  continueMakeOrder: PaymentResponse,
  emptyAddOnCart: EmptyAddOnCart,
  logoutUser: LogoutUserResponse,
  makeAddonOrderWithCardPayment: AddonPaymentResponse,
  makeFreeAddonOrder: AddonPaymentResponse,
  makeOrderWithBankPayment: PaymentResponse,
  makeOrderWithCardPayment: PaymentResponse,
  makeOrderWithPayInternalPayment: PaymentResponse,
  makeOrderWithWalletPayment: PaymentResponse,
  modifyProductSelection: ModifyProductSelectionResponse,
  modifyTravelerInformation: TravelerInformationResponse,
  rebookEligibility: RebookEligibilityResponse,
  rebookEligibilityPerPnr: RebookEligibilityPerPnrResponse,
  rejectPriceChange: RejectPriceChangeResponse,
  removeDiscounts: removeDiscountsResponse,
  removeOrderFromSession: RemoveOrderFromSessionResponse,
  selectLanguage: SelectLanguageResponse,
  selectTrip: SelectTripResponse,
  sendChallengeEmail: SendChallengeEmailResponse,
  sendPostBookingEmailToGenesysV2: sendPostBookingEmailToGenesysResponse,
  setSettings: SetSettingsResponse,
  storeStatistics: PropTypes.bool,
  updateSelectedTripBeforeReservation: UpdateSelectedTripBeforeReservationResponse,
  verifyEmailChallenge: VerifyEmailChallengeResponse,
});

export const traveler = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const CartProduct = PropTypes.shape({
  code: PropTypes.string,
  id: ID,
  name: PropTypes.string,
  price: Price,
  productSummaryAlternativeName: PropTypes.string,
  text: PropTypes.string,
  travelers: PropTypes.arrayOf(traveler),
});

export const PaymentLinkCart = PropTypes.shape({
  cartType: CartType,
  chargeCurrencyPriceFormat: PriceFormat,
  id: ID,
  price: Price,
  priceInChargeCurrency: Price,
  products: PropTypes.arrayOf(CartProduct),
});

export const travelerInformation = PropTypes.shape({
  contactInformation: ContactInformation,
  emergencyContactInformation: EmergencyContactInformation,
  subscribeToNewsletter: PropTypes.bool,
  travelers: PropTypes.arrayOf(DBFTraveler),
});

export const QueryRoot = PropTypes.shape({
  AncillaryPasses: AncillaryPasses,
  ancillaryAvailabilityForOrder: AncillaryAvailability,
  ancillaryBundle: PropTypes.arrayOf(BundleProduct),
  ancillaryItem: ExtraProduct,
  ancillaryItems: PropTypes.arrayOf(ExtraProduct),
  bestPricesPerDay: BestPricesPerDay,
  bestPricesPerPeriod: BestPricesPerPeriod,
  brandInformation: BrandInformationResponse,
  cart: Cart,
  checkInStatus: CheckInStatus,
  consents: PropTypes.arrayOf(consent),
  contactUsDetails: ContactUsDetails,
  countries: PropTypes.arrayOf(Country),
  customerProductSelection: customerProductSelection,
  faq: faq,
  instalmentOptions: InstalmentOptions,
  invoicePdfV2: InvoicePdf,
  links: Links,
  localization: Localization,
  notifications: PropTypes.arrayOf(Notification),
  orderList: PropTypes.arrayOf(OrderInfo),
  orderNew: OrderNew,
  orderStatus: OrderStatus,
  paymentData: PaymentData,
  paymentLinkCart: PaymentLinkCart,
  paymentMethodIssuerOptions: PropTypes.arrayOf(PaymentMethodIssuer),
  paymentStatus: PaymentStatusResponse,
  pendingProviderBooking: pendingProviderBooking,
  rebook: Rebook,
  rebookingSearch: RebookingSearch,
  receiptPdf: ReceiptPdf,
  reissue: Reissue,
  search: Search,
  searchLocations: PropTypes.arrayOf(SearchLocation),
  selectedTrip: Trip,
  selfServiceRebooking: selfServiceRebooking,
  seoData: SeoData,
  settings: Settings,
  siteContext: SiteContext,
  siteInformation: SiteInformation,
  travelDocsInfo: TravelDocsInfo,
  travelerDetails: TravelerDetails,
  travelerInformation: travelerInformation,
  updateFop: UpdateFop,
  validateEmail: EmailValidationResponse,
  validatePhoneNumber: PhoneNumberValidationResponse,
  verifyUser: User,
});

